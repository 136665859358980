import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ManufacturingPlantTranspositionsRepository extends BaseRepository {
    baseUrl = 'bituminous-mixtures/mixtures/manufacturing-plant-transpositions';

    /**
     * Fetches the next (possible) number
     *
     * @returns {*}
     */
    nextNumber(lotInterventionId, teamId) {
        return this.httpClient.get(`${this.baseUrl}/${lotInterventionId}/${teamId}/next-number`);
    }

    /**
     * Fetches all by formulation study id
     *
     * @param formulationStudyId
     * @return {*}
     */
    byFormulationStudyId(formulationStudyId) {
        return this.httpClient.get(`${this.baseUrl}/formulation-study/${formulationStudyId}`);
    }

    /**
     * Fetches all by formulation study id
     *
     * @param formulationStudyId
     * @return {*}
     */
    validatedByFormulationStudyId(formulationStudyId) {
        return this.httpClient.get(`${this.baseUrl}/formulation-study/${formulationStudyId}/validated`);
    }

    /**
     * Fetches all by formulation study id
     *
     * @param approvedWorkingFormulaId
     * @return {*}
     */
    validatedByApprovedWorkingFormulaId(approvedWorkingFormulaId) {
        return this.httpClient.get(`${this.baseUrl}/approved-working-formula/${approvedWorkingFormulaId}`);
    }

    /**
     * Search additional informations by criteria
     *
     * @param mixtureId
     * @param criteria
     * @return {*}
     */
    searchAdditionalInformations(mixtureId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${mixtureId}/additional-informations`, criteria);
    }

    /**
     * Gets the lab with ttmc by current lot intervention
     *
     * @return {*}
     */
    getTTMCbyCurrentLotIntervention() {
        return this.httpClient.get(`${this.baseUrl}/ttmc-by-current-lot-intervention`);
    }
}
