import { DialogService }                              from 'aurelia-dialog';
import { bindable, inject }                           from 'aurelia-framework';
import { I18N }                                       from 'aurelia-i18n';
import { BaseListViewModel }                          from 'base-list-view-model';
import { CreateFormulationStudyCompositionDataModal } from 'modules/bituminous-mixtures/mixtures/formulation-studies/composition-data/create';
import { EditFormulationStudyCompositionDataModal }   from 'modules/bituminous-mixtures/mixtures/formulation-studies/composition-data/edit';
import { FilterFormSchema }                           from 'modules/bituminous-mixtures/mixtures/formulation-studies/composition-data/filter-form-schema';
import { FormulationStudyCompositionDatasRepository } from 'modules/bituminous-mixtures/mixtures/formulation-studies/composition-data/services/repository';
import { ViewFormulationStudyCompositionDataModal }   from 'modules/bituminous-mixtures/mixtures/formulation-studies/composition-data/view';
import { CompositionField }                           from 'modules/management/models/composition-field';
import { AppContainer }                               from 'resources/services/app-container';

@inject(AppContainer, FormulationStudyCompositionDatasRepository, FilterFormSchema, I18N, DialogService)
export class ListFormulationStudyCompositionDatas extends BaseListViewModel {

    listingId = 'formulation-studies-composition-data-listing';

    @bindable headerTitle = null;
    @bindable repository;
    @bindable datatable;
    @bindable bmFormulationStudy;

    readonly = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     * @param i18N
     * @param dialogService
     */
    constructor(appContainer, repository, filterFormSchema, i18N, dialogService) {
        super(appContainer);

        this.i18N             = i18N;
        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'bituminous-mixtures.mixtures.formulation-studies.manage',
            'bituminous-mixtures.mixtures.formulation-studies.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.bmFormulationStudy = params.model;
        this.readonly           = params.readonly;

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = this.filterFormSchema.schema(this);

        return this.getCompositionDataColumns().then(() => {
            this.defineDatatable();
            this.schema = this.datatable;
        });
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.search(criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            show:            {
                action:  (row) => this.openModal({ row: row, model: this.bmFormulationStudy }, ViewFormulationStudyCompositionDataModal),
                visible: (row) => this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.formulation-studies.manage', 'bituminous-mixtures.mixtures.formulation-studies.view']),
            },
            edit:            {
                action:  (row) => this.openModal({ row: row, model: this.bmFormulationStudy }, EditFormulationStudyCompositionDataModal),
                visible: (row) => !this.readonly && this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.formulation-studies.manage', 'bituminous-mixtures.mixtures.formulation-studies.edit']),
            },
            destroy:         !this.readonly && this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.formulation-studies.manage', 'bituminous-mixtures.mixtures.formulation-studies.delete']),
            destroySelected: !this.readonly,
            destroyed:       (response) => {
                return this.getCompositionDataColumns().then(() => {
                    this.schema.columns = this.columns;
                });
            },
            showPagination:  false,
            actions:         [],
            options:         [],
            totalRow:        {
                visible: true,
                columns: [
                    {
                        data:       CompositionField.PERCENTAGE_FIELD_NAME,
                        thresholds: [
                            { value: 100, class: 'text-danger' },
                        ],
                    },
                ],
            },
            buttons:         [
                {
                    label:            'form.button.create-new',
                    icon:             'icon-plus3',
                    visible:          !this.readonly && this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.formulation-studies.manage', 'bituminous-mixtures.mixtures.formulation-studies.create']),
                    action:           () => this.openModal(this, CreateFormulationStudyCompositionDataModal),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:      true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         this.columns,
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.repository.search({ ...criteria, bm_formulation_study_id: this.bmFormulationStudy.id });
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    return this.getCompositionDataColumns().then(() => {
                        this.datatable.instance.reload();
                        this.schema.columns = this.columns;
                    });
                }
            });
    }

    /**
     * Get composition data columns
     */
    getCompositionDataColumns() {
        return this.repository.possibleFields(this.bmFormulationStudy.id).then((response) => {
            this.columns =
                [
                    {
                        data:  'name',
                        name:  'composition_data_translations.name',
                        title: 'form.field.field',
                    },
                    {
                        data:  'composition_value',
                        name:  'bm_formulation_study_composition_data.composition_value',
                        title: 'form.field.value',
                    },
                    {
                        data:      CompositionField.PROVENIENCE_FIELD_NAME,
                        name:      CompositionField.PROVENIENCE_FIELD_NAME + '.value',
                        title:     'form.field.' + CompositionField.PROVENIENCE_FIELD_NAME,
                        type:      'processed',
                        processor: (row) => row[CompositionField.PROVENIENCE_FIELD_NAME] !== undefined ? row[CompositionField.PROVENIENCE_FIELD_NAME] : '-',
                        display:   response.length > 0 && response.find((field) => field.field_name === CompositionField.PROVENIENCE_FIELD_NAME) !== undefined,
                    },
                    {
                        data:      CompositionField.NATURE_FIELD_NAME,
                        name:      CompositionField.NATURE_FIELD_NAME + '.value',
                        title:     'form.field.' + CompositionField.NATURE_FIELD_NAME,
                        type:      'processed',
                        processor: (row) => row[CompositionField.NATURE_FIELD_NAME] !== undefined ? row[CompositionField.NATURE_FIELD_NAME] : '-',
                        display:   response.length > 0 && response.find((field) => field.field_name === CompositionField.NATURE_FIELD_NAME) !== undefined,
                    },
                    {
                        data:      CompositionField.DENSITY_FIELD_NAME,
                        name:      CompositionField.DENSITY_FIELD_NAME + '.value',
                        title:     'form.field.' + CompositionField.DENSITY_FIELD_NAME,
                        type:      'processed',
                        processor: (row) => row[CompositionField.DENSITY_FIELD_NAME] !== undefined ? row[CompositionField.DENSITY_FIELD_NAME] : '-',
                        display:   response.length > 0 && response.find((field) => field.field_name === CompositionField.DENSITY_FIELD_NAME) !== undefined,
                    },
                    {
                        data:      CompositionField.SUPPLIER_FIELD_NAME,
                        name:      CompositionField.SUPPLIER_FIELD_NAME + '.value',
                        title:     'form.field.' + CompositionField.SUPPLIER_FIELD_NAME,
                        type:      'processed',
                        processor: (row) => row[CompositionField.SUPPLIER_FIELD_NAME] !== undefined ? row[CompositionField.SUPPLIER_FIELD_NAME] : '-',
                        display:   response.length > 0 && response.find((field) => field.field_name === CompositionField.SUPPLIER_FIELD_NAME) !== undefined,
                    },
                    {
                        data:      CompositionField.VOLUMETRIC_MASS_FIELD_NAME,
                        name:      CompositionField.VOLUMETRIC_MASS_FIELD_NAME + '.value',
                        title:     'form.field.' + CompositionField.VOLUMETRIC_MASS_FIELD_NAME,
                        type:      'processed',
                        processor: (row) => row[CompositionField.VOLUMETRIC_MASS_FIELD_NAME] !== undefined ? row[CompositionField.VOLUMETRIC_MASS_FIELD_NAME] : '-',
                        display:   response.length > 0 && response.find((field) => field.field_name === CompositionField.VOLUMETRIC_MASS_FIELD_NAME) !== undefined,
                    },
                    {
                        data:      CompositionField.UNITS_FIELD_NAME,
                        name:      CompositionField.UNITS_FIELD_NAME + '.value',
                        title:     'form.field.' + CompositionField.UNITS_FIELD_NAME,
                        type:      'processed',
                        processor: (row) => row[CompositionField.UNITS_FIELD_NAME] !== undefined ? row[CompositionField.UNITS_FIELD_NAME] : '-',
                        display:   response.length > 0 && response.find((field) => field.field_name === CompositionField.UNITS_FIELD_NAME) !== undefined,
                    },
                    {
                        data:      CompositionField.PERCENTAGE_FIELD_NAME,
                        name:      CompositionField.PERCENTAGE_FIELD_NAME + '.value',
                        title:     'form.field.' + CompositionField.PERCENTAGE_FIELD_NAME,
                        type:      'processed',
                        unit:      '%',
                        processor: (row) => row[CompositionField.PERCENTAGE_FIELD_NAME] !== undefined ? row[CompositionField.PERCENTAGE_FIELD_NAME] : '-',
                    },
                ];
        });
    }
}
