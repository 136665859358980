import { EventAggregator }             from 'aurelia-event-aggregator';
import { inject }                      from 'aurelia-framework';
import { I18N }                        from 'aurelia-i18n';
import { BooleanStatus }               from 'modules/administration/models/boolean-status';
import { ConstructionFilterFormSchema } from 'modules/bituminous-mixtures/mixtures/bm-tests/tab-schemas/filter-construction-schema';
import { HighwaysRepository }          from 'modules/management/concessions/concessions-tree/highways/services/repository';
import { InterventionTypesRepository } from 'modules/management/concessions/concessions-tree/intervention-types/services/repository';
import { OEDatasRepository }           from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/services/repository';
import { LotInterventionsRepository }  from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { PkTypesRepository }           from 'modules/management/concessions/concessions-tree/pk-types/services/repository';
import { EntitiesRepository }          from 'modules/management/concessions/entities/services/repository';
import { TeamsRepository }             from 'modules/management/concessions/teams/services/repository';
import { FieldsRepository }            from 'modules/management/dynamic-fields/association-sectors/services/fields-repository';
import { LotConstructionType }         from 'modules/management/models/lot-construction-type';
import { Sector }                      from 'modules/management/models/sector';
import { ConcessionsRepository }       from 'modules/management/specifications/concessions/services/repository';
import { ListingRevisionsRepository }  from 'modules/management/specifications/listings/listing-revisions/services/repository';
import { ListingsRepository }          from 'modules/management/specifications/listings/services/repository';
import { BaseFilterFormSchema }        from 'resources/classes/base-filter-form-schema';
import { SessionStorage }              from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, ConcessionsRepository, EntitiesRepository, TeamsRepository, HighwaysRepository, InterventionTypesRepository, FieldsRepository, ListingsRepository, ListingRevisionsRepository, LotInterventionsRepository, OEDatasRepository, PkTypesRepository)
export class FilterFormSchema extends ConstructionFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        created_by:      [],
        created_at_from: null,
        created_at_to:   null,
    };

    schemaSlug = 'lab_fields';

    constructor(storage, eventAggregator, i18n, concessionsRepository, entitiesRepository, teamsRepository, highwaysRepository, interventionTypesRepository, fieldsRepository, listingsRepository, listingRevisionsRepository, lotInterventionsRepository, oeDatasRepository, pkTypesRepository) {
        super(storage, eventAggregator, i18n, oeDatasRepository, pkTypesRepository);

        this.concessionsRepository       = concessionsRepository;
        this.entitiesRepository          = entitiesRepository;
        this.teamsRepository             = teamsRepository;
        this.highwaysRepository          = highwaysRepository;
        this.interventionTypesRepository = interventionTypesRepository;
        this.fieldsRepository            = fieldsRepository;
        this.listingsRepository          = listingsRepository;
        this.listingRevisionsRepository  = listingRevisionsRepository;
        this.lotInterventionsRepository  = lotInterventionsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param isBuilder
     * @returns {*[]}
     */
    async schema(viewModel, isBuilder = false) {

        // Define Construction fields filters
        await super.schema(viewModel);

        const LAB_WORK_CONTROL = Sector.LAB_WORK_CONTROL;
        const LAB_USE_IN       = Sector.LAB_USE_IN;

        this.test_number_from = {
            type:     'number',
            key:      'test_number_from',
            label:    'form.field.sample-number(from)',
            size:     3,
            required: false,
        };

        this.test_number_to = {
            type:     'number',
            key:      'test_number_to',
            label:    'form.field.sample-number(to)',
            size:     3,
            required: false,
        };

        this.concession_id = {
            type:         'select2',
            key:          'concession_id',
            label:        'form.field.concession',
            size:         6,
            required:     false,
            remoteSource: () => this.concessionsRepository.allByLoggedUser(),
            observers:    [
                async (newValue, oldValue) => {
                    if (!newValue) {
                        viewModel.filterModel.highway_id           = null;
                        viewModel.filterModel.intervention_type_id = null;
                    }

                    await this.highwaysRepository.groupedAll({
                        filter_by_logged_user: true,
                        concession_id:         newValue,
                    }).then((response) => {
                        this.highway_id.options = this.highway_id.instance.transformOptionsObject(response);
                    });

                    await this.lot_intervention_id.instance.fetchData();
                },
            ],
        };

        this.highway_id = {
            type:         'select2',
            key:          'highway_id',
            label:        'form.field.highway-road',
            size:         6,
            required:     false,
            remoteSource: () => this.highwaysRepository.groupedAll({
                filter_by_logged_user: true,
                concession_id:         viewModel.filterModel.concession_id,
            }),
            observers:    [
                (newValue, oldValue) => {
                    this.intervention_type_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.highwaysRepository.interventionTypes(newValue, { filter_by_logged_user: true }).then((response) => {
                            this.intervention_type_id.options = response;
                            this.intervention_type_id.instance.enable(response.length);
                        });
                    } else {
                        this.intervention_type_id.options = [{ id: null, name: this.i18n.tr('form.field.select-first-highway-road'), status_id: BooleanStatus.INACTIVE }];
                    }
                },
            ],
        };

        this.intervention_type_id = {
            type:         'select2',
            key:          'intervention_type_id',
            label:        'form.field.intervention-type',
            size:         6,
            required:     false,
            remoteSource: () => {
                return viewModel.filterModel.highway_id
                    ? this.highwaysRepository.interventionTypes(viewModel.filterModel.highway_id, { filter_by_logged_user: true })
                    : Promise.resolve([{ id: null, name: this.i18n.tr('form.field.select-first-highway-road'), status_id: BooleanStatus.INACTIVE }]);
            },
            observers:    [
                (newValue, oldValue) => {
                    return this.lotInterventionsRepository.groupedAll({
                        filter_by_logged_user: true,
                        concession_id:         viewModel.filterModel.concession_id,
                        highway_id:            viewModel.filterModel.highway_id,
                        intervention_type_id:  newValue,
                    }).then((response) => {
                        this.lot_intervention_id.options = this.lot_intervention_id.instance.transformOptionsObject(response);
                    });
                },
            ],
        };

        this.lot_intervention_id = {
            type:         'select2',
            key:          'lot_intervention_id',
            label:        'form.field.lot-intervention',
            size:         6,
            required:     false,
            remoteSource: () => this.lotInterventionsRepository.groupedAll({
                filter_by_logged_user: true,
                concession_id:         viewModel.filterModel.concession_id,
                highway_id:            viewModel.filterModel.highway_id,
                intervention_type_id:  viewModel.filterModel.intervention_type_id,
            }),
            observers:    [
                (newValue) => {
                    this.lot_construction_id.instance.disable(!newValue);
                    this.listing_revision_id.instance.disable(!newValue);

                    // Based on new value for lot intervention
                    if (newValue) {
                        // Refresh filter for lot constructions
                        this.lotInterventionsRepository.allConstructions(newValue, LotConstructionType.OE).then((response) => {
                            this.lot_construction_id.options = response;
                            this.lot_construction_id.instance.enable(response.length);
                        });
                        // Refresh filter for listing revisions
                        this.listingsRepository.allFromSector(viewModel.sectorId, {
                            lot_intervention_id: newValue,
                        }).then((response) => {
                            this.listing_revision_id.options = response;
                            this.listing_revision_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.entity_id = {
            type:         'select2',
            key:          'entity_id',
            label:        'form.field.entity',
            size:         6,
            remoteSource: () => this.entitiesRepository.all(),
            required:     false,
            attributes:   {
                disabled: false,
            },
            observers:    [
                (newValue) => {
                    this.team_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.entitiesRepository.teams(newValue).then((response) => {
                            this.team_id.options = response;
                            this.team_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.team_id = {
            type:         'select2',
            key:          'team_id',
            label:        'form.field.team',
            size:         6,
            remoteSource: () => (viewModel.filterModel.entity_id)
                ? this.entitiesRepository.teams(viewModel.filterModel.entity_id)
                : Promise.resolve([]),
            required:     false,
            attributes:   {
                disabled: !(viewModel.filterModel.entity_id),
            },
        };

        this.external_reference = {
            type:     'text',
            key:      'external_reference',
            label:    'form.field.external-reference',
            size:     6,
            required: false,
        };

        this.sample_collection_id = {
            type:         'multiselect-native',
            key:          'sample_collection_id',
            label:        'form.field.sample-collection',
            remoteSource: () => this.fieldsRepository.getFields(viewModel.sectorId,
                'sample-collection',
                { slug: [LAB_WORK_CONTROL, LAB_USE_IN] },
            ),
            size:         6,
            required:     false,
        };

        this.date_from = {
            type:     'material-ui-date-picker',
            key:      'date_from',
            label:    'form.field.collection-date(from)',
            size:     3,
            required: false,
        };

        this.date_to = {
            type:     'material-ui-date-picker',
            key:      'date_to',
            label:    'form.field.collection-date(to)',
            size:     3,
            required: false,
        };

        this.listing_revision_id = {
            type:         'select2',
            key:          'listing_revision_id',
            label:        'form.field.listings',
            size:         6,
            remoteSource: () => this.listingsRepository.allFromSector(viewModel.sectorId, {
                lot_intervention_id: viewModel.lot_intervention_id,
            }),
            required:     false,
            observers:    [
                (newValue) => {
                    this.bulletin_id.instance.disable(!newValue);

                    if (newValue) {
                        this.listingRevisionsRepository.getBulletins(newValue).then((response) => {
                            this.bulletin_id.options = response;
                            this.bulletin_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.bulletin_id = {
            type:         'select2',
            key:          'bulletin_id',
            label:        'form.field.tests',
            size:         6,
            remoteSource: () => Promise.resolve([]),
            required:     false,
        };

        this.validation_status = {
            type:     'boolean-options-select',
            key:      'validation_status',
            label:    'form.field.validation-status',
            size:     4,
            required: false,
        };

        this.inspection_status = {
            type:     'boolean-options-select',
            key:      'inspection_status',
            label:    'form.field.inspection-status',
            size:     4,
            required: false,
        };

        this.concessionary_status = {
            type:     'boolean-options-select',
            key:      'concessionary_status',
            label:    'form.field.concessionary-status',
            size:     4,
            required: false,
        };

        this.created_by = {
            type:     'user-entity-team-lot-multiselect',
            key:      'created_by',
            label:    'form.field.created-by',
            size:     4,
            required: false,
        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.created-at(from)',
            size:     4,
            required: false,
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.created-at(to)',
            size:     4,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.test_number_from, this.test_number_to, this.concession_id],
            [this.entity_id, this.team_id],
            [this.highway_id, this.intervention_type_id],
            [this.lot_intervention_id, this.external_reference],
            [this.sample_collection_id, this.date_from, this.date_to],
            [this.listing_revision_id, this.bulletin_id],
            [this.lot_construction_id, this.branch_id, this.edge_id],
            [this.collection_pk, this.pk_type_id],
            [this.validation_status, this.inspection_status, this.concessionary_status],
            [this.created_by, this.created_at_from, this.created_at_to],
            [this.buttons],
        ];
    }
}
