import { inject }                from 'aurelia-framework';
import { AdditionalInfoSchema }  from 'modules/aggregates/lab/tab-schemas/additional-info-schema';
import { GeneralSchema }         from 'modules/bituminous-mixtures/mixtures/bm-tests/tab-schemas/general-schema';
import { HarvestDataSchema }     from 'modules/bituminous-mixtures/mixtures/bm-tests/tab-schemas/harvest-data-schema';
import { SelectBulletinsSchema } from 'modules/bituminous-mixtures/mixtures/bm-tests/tab-schemas/select-bulletins-schema';
import { BMTest }                from 'modules/bituminous-mixtures/models/bm-test';

@inject(SelectBulletinsSchema, GeneralSchema, HarvestDataSchema, AdditionalInfoSchema)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {};

    constructor(selectBulletinsSchema, generalSchema, harvestDataSchema, additionalInfoSchema) {
        this.selectBulletinsSchema = selectBulletinsSchema;
        this.generalSchema         = generalSchema;
        this.harvestDataSchema     = harvestDataSchema;
        this.additionalInfoSchema  = additionalInfoSchema;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {BMTest}
     */
    model() {
        let model = new BMTest();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    lotInfoSchema(viewModel) {

        this.concession_name = {
            type:       'text',
            key:        'concession_name',
            label:      'form.field.concession',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.entity_name = {
            type:       'text',
            key:        'entity_name',
            label:      'form.field.entity',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.team_name = {
            type:       'text',
            key:        'team_name',
            label:      'form.field.team',
            size:       3,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.highway_name = {
            type:       'text',
            key:        'highway_name',
            label:      'form.field.highway',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.intervention_type_name = {
            type:       'text',
            key:        'intervention_type_name',
            label:      'form.field.intervention-type',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.lot_intervention_name = {
            type:       'text',
            key:        'lot_intervention_name',
            label:      'form.field.lot-intervention',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        return [
            [this.concession_name, this.entity_name, this.team_name],
            [this.highway_name, this.intervention_type_name],
            [this.lot_intervention_name],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute(
                {
                    name:   viewModel.backRoute,
                    params: viewModel.backRouteParams,
                }),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [
                [this.created_by, this.created_at],
                [this.buttons],
            ];
        }

        return [
            [this.buttons],
        ];
    }

}
