import { bindable, bindingMode, inject } from 'aurelia-framework';
import { I18N }                          from 'aurelia-i18n';
import { BaseListViewModel }             from 'base-list-view-model';
import { HighwaysFilterFormSchema }      from 'modules/management/concessions/concessions-tree/highways/listing/filter-form-schema';
import { HighwaysRepository }            from 'modules/management/concessions/concessions-tree/highways/services/repository';
import { AppContainer }                  from 'resources/services/app-container';
import { Downloader }                    from 'resources/services/downloader';

@inject(AppContainer, HighwaysRepository, HighwaysFilterFormSchema, I18N, Downloader)
export class ListHighways extends BaseListViewModel {

    headerTitle    = 'listing.management.concessions.highways';
    newRecordRoute = 'management.concessions.highways.create';
    repository;
    datatable;

    ignoredFilters = ['parent_id', 'parent_key'];

    @bindable({ defaultBindingMode: bindingMode.twoWay }) listingId;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterModel;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterSchema;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterFormIsDirty;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) displayTree;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     * @param i18N
     * @param downloader
     */
    constructor(appContainer, repository, filterFormSchema, i18N, downloader) {
        super(appContainer);

        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;
        this.i18N             = i18N;
        this.downloader       = downloader;
    }

    /**
     * Handles activate event
     */
    bind() {
        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this, this.filterModel.parent_id, this.filterModel.parent_key);

        this.listingId = 'management-concessions-highways-listing'

        this.defineDatatable();
    }

    /**
     * Toggles the tree visibility
     */
    toggleTree() {
        this.displayTree = !this.displayTree;
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.concessions.highways.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.concessions.concessions.manage', 'management.concessions.concessions.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.concessions.highways.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.concessions.concessions.manage', 'management.concessions.concessions.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.concessions.concessions.manage', 'management.concessions.concessions.delete']),
            },
            options:         [],
            selectable:      true,
            destroySelected: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'code',
                    name:  'highways.code',
                    title: 'form.field.code',
                },
                {
                    data:  'highway_number',
                    name:  'highways.highway_number',
                    title: 'form.field.highway-number',
                },
                {
                    data:  'designation',
                    name:  'highway_translations.designation',
                    title: 'form.field.highway-designation',
                },
                {
                    data:            'created_at',
                    name:            'highways.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
            ],
        };
    }
}
