import { DialogService }             from 'aurelia-dialog';
import { bindable, inject }          from 'aurelia-framework';
import { I18N }                      from 'aurelia-i18n';
import { BaseOrderedListViewModel }  from 'base-ordered-list-view-model';
import { FilterFormSchema }          from 'modules/map/management/manufacturers/filter-form-schema';
import { ReclassifyManufacturers }   from 'modules/map/management/manufacturers/reclassify/';
import { ManufacturersRepository }   from 'modules/map/management/manufacturers/services/repository';
import { MatAppProposalsRepository } from 'modules/map/mat-app-proposals/services/repository';
import { InfoDialog }                from 'resources/elements/html-elements/dialogs/info-dialog';
import { AppContainer }              from 'resources/services/app-container';
import { Downloader }                from 'resources/services/downloader';

@inject(AppContainer, ManufacturersRepository, I18N, Downloader, FilterFormSchema, DialogService, MatAppProposalsRepository)
export class ListManufacturers extends BaseOrderedListViewModel {

    listingId = 'manufacturers-listing';

    @bindable headerTitle    = 'listing.map.management.manufacturers';
    @bindable newRecordRoute = 'map.management.manufacturers.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     * @param dialogService
     * @param matAppProposalsRepository
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService, matAppProposalsRepository) {
        super(appContainer);

        this.repository                = repository;
        this.i18N                      = i18N;
        this.downloader                = downloader;
        this.filterFormSchema          = filterFormSchema;
        this.dialogService             = dialogService;
        this.matAppProposalsRepository = matAppProposalsRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('map.management.manufacturers.index') && super.can([
            'map.management.manufacturers.manage',
            'map.management.manufacturers.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         this.repository,
            show:               {
                action:  (row) => this.appContainer.router.navigateToRoute('map.management.manufacturers.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.management.manufacturers.manage', 'map.management.manufacturers.view']),
            },
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute('map.management.manufacturers.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.management.manufacturers.manage', 'map.management.manufacturers.edit']),
            },
            destroy:            {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.management.manufacturers.manage', 'map.management.manufacturers.delete']),
            },
            options:            [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('listing.map.management.manufacturers'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['map.management.manufacturers.manage', 'map.management.manufacturers.view']),
                },
            ],
            selectable:         true,
            destroySelected:    true,
            actionsContextMenu: true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:  'name',
                    name:  'manufacturer_translations.name',
                    title: 'form.field.name',
                },
                {
                    data:       'status',
                    name:       'boolean_statuses.name',
                    searchable: false,
                    title:      'form.field.status',
                    type:       'label',
                },
                {
                    data:  'created_by',
                    name:  'user_entity_team_lots.identifier',
                    title: 'form.field.created-by',
                    type:  'userEntityTeamLot',
                },
                {
                    data:            'created_at',
                    name:            'manufacturers.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Executes a given action if it is selected at least one row
     *
     * @param action
     * @returns {*}
     */
    ensureSelectedRows(action) {
        if (!this.datatable.instance.selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        action();
    }

    /**
     * Opens the application setting modal
     *
     */
    viewReclassify(selectedRows) {
        this.dialogService
            .open({
                viewModel: ReclassifyManufacturers,
                model:     { selected_ids: selectedRows },
            })
            .whenClosed((response) => this.datatable.instance.reload());
    }

    /**
     * Exports the listing to Excel
     * Assumptions: a `filterModel`, `downloader` instance and repository `export` method must exist
     *
     * @param filename
     * @param selectedRows
     */
    exportListing(filename = 'listing', selectedRows = null) {
        this.repository.export(this.filterModel).then(response => this.downloader.download(response, filename, 'xlsx'));
    }

}
