import { DialogService }                               from 'aurelia-dialog';
import { bindable, inject }                            from 'aurelia-framework';
import { I18N }                                        from 'aurelia-i18n';
import { PLATFORM }                                    from 'aurelia-pal';
import { activationStrategy }                          from 'aurelia-router';
import { BaseOrderedListViewModel }                    from 'base-ordered-list-view-model';
import { FormulationStudyAdditionalInformationsModal } from 'modules/bituminous-mixtures/mixtures/formulation-studies/additional-informations-modal';
import { FilterFormSchema }                            from 'modules/dynamic-lists/mixtures/formulation-studies/filter-form-schema';
import { DynamicListsRepository }                      from 'modules/dynamic-lists/services/repository';
import { InfoDialog }                                  from 'resources/elements/html-elements/dialogs/info-dialog';
import { AppContainer }                                from 'resources/services/app-container';
import { Downloader }                                  from 'resources/services/downloader';

@inject(AppContainer, DynamicListsRepository, I18N, Downloader, FilterFormSchema, DialogService)
export class DynamicListFormulationStudy extends BaseOrderedListViewModel {

    routePrefix      = 'dynamic-listings.mixtures.formulation-studies';
    permissionPrefix = this.routePrefix;

    @bindable headerTitle = 'listing.dynamic-lists.formulation-studies';
    @bindable newRecordRoute;
    @bindable newRecordRouteParams;
    @bindable repository;
    @bindable datatable;

    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Determines activation strategy
     *
     * @return {'replace'}
     */
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    /**
     * Handles canActivate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async canActivate(params) {
        return true;
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.defineDatatable();

        this.authUser = this.appContainer.authenticatedUser;

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = await this.filterFormSchema.schema(this, this.authUser.isBuilder);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         {
                search: (criteria) => this.repository.formulationStudy(criteria),
            },
            actionsContextMenu: false,
            selectable:         true,
            destroySelected:    false,
            options:            [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.formulation-study'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: () => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.view']),
                },
            ],
            sorting:            [
                {
                    column:    0,
                    direction: 'desc',
                },
                {
                    column:    4,
                    direction: 'desc',
                },
            ],
            columns:            [
                {
                    data:  'concession_name',
                    name:  'concession_translations.description',
                    title: 'form.field.concession',
                },
                {
                    data:       'entity_name',
                    name:       'entity_translations.name',
                    title:      'form.field.entity',
                    searchable: false,
                    orderable:  false,
                    type:       'processed',
                    processor:  (row) => this.getEntityWithTeam(row),
                },
                {
                    data:  'highway_name',
                    name:  'highway_translations.designation',
                    title: 'form.field.highway',
                },
                {
                    data:  'intervention_type_name',
                    name:  'intervention_type_translations.designation',
                    title: 'form.field.intervention-type',
                },
                {
                    data:  'lot_intervention_name',
                    name:  'lot_intervention_translations.designation',
                    title: 'form.field.lot',
                },
                {
                    data:  'number',
                    name:  'bm_formulation_studies.number',
                    title: 'form.field.study-number',
                },
                {
                    data:  'date',
                    name:  'bm_formulation_studies.date',
                    title: 'form.field.date',
                },
                {
                    data:  'approval_date',
                    name:  'bm_formulation_studies.approval_date',
                    title: 'form.field.approval-date',
                },
                {
                    data:  'study_type_name',
                    name:  'study_types.study_type',
                    title: 'form.field.study-type',
                },
                {
                    data:  'material_type_name',
                    name:  'material_type_translations.name',
                    title: 'form.field.material-type',
                },
                {
                    data:  'layer_name',
                    name:  'bm_layer_translations.name',
                    title: 'form.field.layer-name',
                },
                {
                    data:  'designation',
                    name:  'bm_formulation_study_translations.designation',
                    title: 'form.field.mixture-designation',
                },
                {
                    data:            'fillers',
                    name:            'fillers',
                    title:           'form.field.filler',
                    type:            'custom-cell',
                    customStyle:     'max-width: 100%',
                    searchable:      false,
                    orderable:       false,
                    embeddedHeaders: true,
                    viewModel:       PLATFORM.moduleName('modules/core/custom-listing-cells/nested-table-cell/index'),
                },
                {
                    data:            'fractions',
                    name:            'fractions',
                    title:           'form.field.fraction',
                    type:            'custom-cell',
                    customStyle:     'max-width: 100%',
                    searchable:      false,
                    orderable:       false,
                    embeddedHeaders: true,
                    viewModel:       PLATFORM.moduleName('modules/core/custom-listing-cells/nested-table-cell/index'),
                },
                {
                    data:            'ligands',
                    name:            'ligands',
                    title:           'form.field.ligand',
                    type:            'custom-cell',
                    customStyle:     'max-width: 100%',
                    searchable:      false,
                    orderable:       false,
                    embeddedHeaders: true,
                    viewModel:       PLATFORM.moduleName('modules/core/custom-listing-cells/nested-table-cell/index'),
                },
                {
                    data:            'others',
                    name:            'others',
                    title:           'form.field.other',
                    type:            'custom-cell',
                    customStyle:     'max-width: 100%',
                    searchable:      false,
                    orderable:       false,
                    embeddedHeaders: true,
                    viewModel:       PLATFORM.moduleName('modules/core/custom-listing-cells/nested-table-cell/index'),
                },
                {
                    data:            'properties',
                    name:            'properties',
                    title:           'form.field.properties',
                    type:            'custom-cell',
                    customStyle:     'max-width: 100%',
                    searchable:      false,
                    orderable:       false,
                    embeddedHeaders: true,
                    viewModel:       PLATFORM.moduleName('modules/core/custom-listing-cells/nested-table-cell/index'),
                },
                {
                    data:  'created_by',
                    name:  'user_entity_team_lots.identifier',
                    title: 'form.field.created-by',
                    type:  'userEntityTeamLot',
                },
                {
                    data:            'created_at',
                    name:            'bm_formulation_studies.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:          'is_validated',
                    name:          'is_validated',
                    title:         'form.field.validated',
                    type:          'custom-cell',
                    fullDataModel: 'validator',
                    viewModel:     PLATFORM.moduleName('modules/core/custom-listing-cells/checkmark/index'),
                    orderable:     false,
                    searchable:    false,
                },
                {
                    data:       'additional_info',
                    name:       'additional_info',
                    title:      'form.title.additional-info',
                    type:       'custom-cell',
                    icon:       'icon-info3',
                    show:       (row) => row.has_additional_information,
                    action:     (row) => this.openModal(row.id, FormulationStudyAdditionalInformationsModal),
                    viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/action-icon/index'),
                    orderable:  false,
                    searchable: false,
                },
                {
                    data:    'observations',
                    name:    'bm_formulation_studies.observations',
                    title:   'form.field.observations',
                },
            ],
        };
    }

    /**
     * Executes a given action if it is selected at least one row
     *
     * @param action
     * @returns {*}
     */
    ensureSelectedRows(action) {
        if (!this.datatable.instance.selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        action();
    }

    /**
     * Call promise then show response notice and reload datatable
     * @param call
     */
    callThenResponse(call) {
        call.then(response => {
            this.appContainer.notifier.responseNotice(response);
            this.datatable.instance.reload();
        });
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }

    /**
     * Exports the listing to Excel
     * Assumptions: a `filterModel`, `downloader` instance and repository `export` method must exist
     *
     * @param filename
     * @param selectedRows
     */
    exportListing(filename = 'listing', selectedRows = null) {
        const filterModel = Object.assign({
            sorting: this.datatable.instance.getSortingColumn(),
        }, this.filterModel);

        if (selectedRows) {
            filterModel.selectedRows = selectedRows;
        }

        this.repository.exportFormulationStudy(filterModel).then(response => this.downloader.download(response, filename, 'xlsx'));
    }

    /**
     * Processed cell for Entity (Team)
     *
     * @param row
     * @returns {*}
     */
    getEntityWithTeam(row) {

        let html = row['entity_name'];
        html += '<small><br />(';
        html += row['team_name'];
        html += ')</small>';

        return html;
    }
}

