import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class FormulationStudyCompositionDatasRepository extends BaseRepository {
    baseUrl = 'bituminous-mixtures/mixtures/formulation-studies/composition-data';

    /**
     *
     * @param bmFormulationStudyId
     * @return {*}
     */
    possibleFields(bmFormulationStudyId) {
        return this.httpClient.get(`${this.baseUrl}/${bmFormulationStudyId}/possible-fields`);
    }
}
