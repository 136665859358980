import { DialogController }            from 'aurelia-dialog';
import { bindable, inject }            from 'aurelia-framework';
import { PLATFORM }                    from 'aurelia-pal';
import { BaseListViewModel }           from 'base-list-view-model';
import { SelectedBulletinsRepository } from 'modules/aggregates/selected-bulletins/services/repository';
import { AppContainer }                from 'resources/services/app-container';

@inject(AppContainer, SelectedBulletinsRepository, DialogController)
export class SelectedBulletinsListingModal extends BaseListViewModel {

    listingId = 'test-selected-bulletins-modal-listing';

    @bindable headerTitle    = 'test-selected-bulletins-modal-listings';
    @bindable newRecordRoute = null;
    @bindable repository;
    @bindable datatable;

    @bindable alert = {};

    @bindable settings = {
        title:   'form.title.selected-bulletins',
        size:    'modal-lg',
        buttons: false,
    };

    permissionPrefix = 'aggregates';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     */
    constructor(appContainer, repository, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.relatableId = params.relatableId;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.repository.search(this.relatableId, criteria),
                destroy:         null,
                destroySelected: false,
            },
            edit:            null,
            destroy:         null,
            destroySelected: false,
            options:         [],
            buttons:         [],
            selectable:      false,
            actions:         [],
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:   'id',
                    name:   'test_selected_bulletins.id',
                    title:  'form.field.id',
                    hidden: true,
                },
                {
                    data:      'bulletin_name',
                    name:      'bulletin_translations.name',
                    title:     'form.field.bulletin-name',
                    type:      'processed',
                    processor: row => this.prettifyBulletinName(row),
                },
                {
                    data:      'model_file',
                    name:      'model_file',
                    title:     'form.field.model-file',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/aggregates/selected-bulletins/custom-listing-cells/file/index'),
                    orderable: false,
                    searchable: false,
                    center:    true,
                },
                {
                    data:      'is_test_uploaded',
                    name:      'files.display_name',
                    title:     'form.field.is-test-uploaded',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/aggregates/selected-bulletins/custom-listing-cells/is-test-uploaded/index'),
                    orderable: false,
                    center:    true,
                },
                {
                    name:  'operator_name',
                    data:  'operator_name',
                    title: 'form.field.operator-name',
                },
            ],
        };
    }

    /**
     * Prettifies the bulletin name
     *
     * @param row
     * @return {string}
     */
    prettifyBulletinName(row) {
        let html = '<p class="mb-0" style="font-size: 13px">';
        html += row.bulletin_name;
        html += '</p>';
        html += '<span class="text-muted" style="font-size: 12px">';
        html += row.norm;
        html += '&nbsp;&bull;&nbsp;';
        html += row.bulletin_model;
        html += '&nbsp;&bull;&nbsp;(R';
        html += row.revision_number;
        html += ')';
        html += '</span>';
        return html;
    }
}
