import { bindable, containerless } from 'aurelia-framework';

@containerless
export class AureliaModal {
    @bindable settings;
    @bindable action = () => Promise.resolve(true);

    isMinimized = false;

    bind(bindingContext) {
        this.bindingContext = bindingContext;
    }

    attached() {
        this.draggableModal();
        document.getElementsByTagName('body').item(0).classList.add('modal-open');
    }

    draggableModal() {
        jQuery.noConflict();
        jQuery('.modal').draggable({
            handle: '.draggable',
        });
    }

    detached() {
        if (!this.isChild && !$('ux-dialog-container').length) {
            document.getElementsByTagName('body').item(0).classList.remove('modal-open');
        }
    }

    minimize() {
        this.isMinimized = !this.isMinimized;
        const modalBody = document.querySelector('.modal-body');
        const modalFooter = document.querySelector('.modal-footer');
        if (this.isMinimized) {
            modalBody.style.display = 'none';
            if (modalFooter) modalFooter.style.display = 'none';
        } else {
            modalBody.style.display = '';
            if (modalFooter) modalFooter.style.display = '';
        }
    }

    onBackgroundClick(event) {
        if (event.target.closest('.modal-dialog')) {
            return;
        }
         this.cancel();
    }


    submit() {
        return this.action().then((status) => {
            if (status === true) {
                return this.bindingContext.dialogController.ok();
            }
        });
    }

    cancel() {
        return this.bindingContext.dialogController.cancel();
    }
}
