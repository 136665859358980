import { inject }                     from 'aurelia-framework';
import { LayersRepository }           from 'modules/management/bituminous-mixtures/layers/services/repository';
import { SpecificationRevision }      from 'modules/management/models/specification-revision';
import { ListingRevisionsRepository } from 'modules/management/specifications/listings/listing-revisions/services/repository';

@inject(ListingRevisionsRepository, LayersRepository)
export class SpecificationRevisionFormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param listingsRepository
     * @param layersRepository
     */
    constructor(listingsRepository, layersRepository) {
        this.listingsRepository = listingsRepository;
        this.layersRepository   = layersRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {SpecificationRevision}
     */
    model(nextSpecificationRevisionNumber, lastSpecificationEditionNumber) {
        let model = new SpecificationRevision();

        this.modelDefaults.revision_number = nextSpecificationRevisionNumber;
        this.modelDefaults.edition_number  = lastSpecificationEditionNumber;

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.code = {
            type:       'text',
            key:        'code',
            label:      'form.field.code',
            size:       12,
            attributes: {
                readonly: true,
            },
        };

        this.revision_number = {
            type:       'number',
            key:        'revision_number',
            label:      'form.field.revision-number',
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.edition_number = {
            type:       'number',
            key:        'edition_number',
            label:      'form.field.edition-number',
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.effective_date = {
            type:            'material-ui-date-picker',
            key:             'effective_date',
            label:           'form.field.effective-date',
            size:            12,
            valueConverters: [
                { name: 'dateFormat' },
            ],
            attributes:      {
                disabled: readonly,
            },
        };

        this.listings = {
            type:         'duallistbox',
            key:          'listings',
            label:        'form.field.listings',
            required:     false,
            size:         12,
            remoteSource: () => viewModel.concessionId !== null && viewModel.concessionId !== '' && viewModel.concessionId !== undefined
                ? this.listingsRepository.allByConcession(viewModel.concessionId)
                : Promise.resolve([]),
            attributes:   {
                disabled: readonly,
            },
        };

        this.layers = {
            type:         'duallistbox',
            key:          'layers',
            label:        'form.field.layers-mb',
            required:     false,
            size:         12,
            remoteSource: () => this.layersRepository.all(),
            attributes:   {
                disabled: readonly,
            },
        };

        const schema = readonly ? [
            [this.code],
            [this.edition_number, this.revision_number],
            [this.effective_date],
            [this.listings],
            [this.layers],
        ] : [
            [this.edition_number, this.revision_number],
            [this.effective_date],
            [this.listings],
            [this.layers],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
