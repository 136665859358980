import { inject }                          from 'aurelia-framework';
import { PLATFORM }                        from 'aurelia-pal';
import { BaseFormViewModel }               from 'base-form-view-model';
import { FormSchema }                      from 'modules/aggregates/in-situ/form-schema';
import { AggregatesInSituTestsRepository } from 'modules/aggregates/in-situ/services/repository';
import { AggregatesLabTestsRepository }    from 'modules/aggregates/lab/services/repository';
import { AggControlType }                  from 'modules/aggregates/models/control-type';
import { Sector }                          from 'modules/management/models/sector';
import { AppContainer }                    from 'resources/services/app-container';

@inject(AppContainer, AggregatesInSituTestsRepository, AggregatesLabTestsRepository, AggControlType, FormSchema)
export class EditAggregatesInSituTest extends BaseFormViewModel {

    headerTitle = 'form.title.edit-record';
    routePrefix = 'aggregates.in-situ';
    permissionPrefix;

    alert            = {};
    model            = {};
    activeControlTab = false;
    tabs             = {};

    create = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param labRepository
     * @param controlType
     * @param formSchema
     */
    constructor(appContainer, repository, labRepository, controlType, formSchema) {
        super(appContainer);

        this.repository    = repository;
        this.labRepository = labRepository;

        this.controlType = controlType;

        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/aggregates/in-situ/form.html');
    }

    /**
     * Handles canActivate event
     */
    async canActivate(params) {
        this.category = params.category;
        this.repository.setCategory(this.category);
        this.labRepository.setCategory(this.category)

        this.formId           = `aggregates-${this.category}-in-situ-edition-form`;
        this.permissionPrefix = `aggregates.${this.category}.in-situ`;
        this.routePrefix      = `aggregates.${this.category}.in-situ`;
        this.sectorId         = (this.category === 'not-treated')
            ? Sector.AGGREGATES_NT_IN_SITU
            : Sector.AGGREGATES_T_IN_SITU;

        this.backRoute       = `${this.routePrefix}.index`;
        this.backRouteParams = params;

        return this.redirectToSelectLotIntervention(`${this.routePrefix}.edit`, params) && super.can([
            this.permissionPrefix + '.manage',
            this.permissionPrefix + '.edit',
        ]);
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);

        if (this.model.is_validated) {
            return this.redirectToRoute({
                name:   `${this.routePrefix}.view`,
                params: params || {},
            });
        }

        // Get schema for each tab
        this.tabs.selectBulletins       = await this.formSchema.selectBulletinsSchema.schema(this);
        this.tabs.general               = this.formSchema.generalSchema.schema(this);
        this.tabs.general.lotInfoSchema = this.formSchema.lotInfoSchema(this);
        this.tabs.workControl           = await this.formSchema.workControlSchema.schema(this);
        this.tabs.additionalInfo        = this.formSchema.additionalInfoSchema.schema(this);
        this.globalSchema               = this.formSchema.globalSchema(this);

        this.updateControlTabs(this.model.control_type_id);

        this.updateMaxTestsQuantity(this.model.max_tests_quantity);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.update(this.sectorId, this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response, {
                    name:   this.backRoute,
                    params: this.backRouteParams,
                });
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }

    /**
     * Update Control Tabs
     * @param controlTypeId
     */
    updateControlTabs(controlTypeId) {
        this.activeControlTab = controlTypeId;
    }

    updateMaxTestsQuantity(maxTestsQuantity) {
        this.model.max_tests_quantity = maxTestsQuantity;

        let hidden = maxTestsQuantity && maxTestsQuantity > 1;

        this.formSchema.generalSchema.date.hidden              = hidden;
        this.formSchema.workControlSchema.oe_data_id.hidden    = hidden; // Show PK selector
        this.formSchema.workControlSchema.applied_pk.hidden    = hidden; // First PK field
        this.formSchema.workControlSchema.collection_pk.hidden = hidden; // Second PK field
        this.formSchema.workControlSchema.track_id.hidden      = hidden;
        this.formSchema.workControlSchema.depth_quota.hidden   = hidden;
        this.formSchema.workControlSchema.axis_distance.hidden = hidden;

    }
}
