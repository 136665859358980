import { DialogService }                                              from 'aurelia-dialog';
import { bindable, inject }                                           from 'aurelia-framework';
import { I18N }                                                       from 'aurelia-i18n';
import { PLATFORM }                                                   from 'aurelia-pal';
import { activationStrategy }                                         from 'aurelia-router';
import { BaseOrderedListViewModel }                                   from 'base-ordered-list-view-model';
import { ManufacturingPlantTranspositionAdditionalInformationsModal } from 'modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/additional-informations-modal';
import { FilterFormSchema }                                           from 'modules/dynamic-lists/mixtures/manufacturing-plant-transpositions/filter-form-schema';
import { DynamicListsRepository }                                     from 'modules/dynamic-lists/services/repository';
import { InfoDialog }                                                 from 'resources/elements/html-elements/dialogs/info-dialog';
import { AppContainer }                                               from 'resources/services/app-container';
import { Downloader }                                                 from 'resources/services/downloader';

@inject(AppContainer, DynamicListsRepository, I18N, Downloader, FilterFormSchema, DialogService)
export class DynamicListManufacturingPT extends BaseOrderedListViewModel {

    routePrefix      = 'dynamic-listings.mixtures.manufacturing-plant-transpositions';
    permissionPrefix = this.routePrefix;

    @bindable headerTitle = 'listing.dynamic-lists.manufacturing-plant-transpositions';
    @bindable newRecordRoute;
    @bindable newRecordRouteParams;
    @bindable repository;
    @bindable datatable;

    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Determines activation strategy
     *
     * @return {'replace'}
     */
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    /**
     * Handles canActivate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async canActivate(params) {
        return true;
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.defineDatatable();

        this.authUser = this.appContainer.authenticatedUser;

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = await this.filterFormSchema.schema(this, this.authUser.isBuilder);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         {
                search: (criteria) => this.repository.manufacturingPlantTransposition(criteria),
            },
            actionsContextMenu: false,
            selectable:         true,
            destroySelected:    false,
            options:            [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.manufacturing-plant-transposition'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: () => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.view']),
                },
            ],
            sorting:            [
                {
                    column:    0,
                    direction: 'desc',
                },
                {
                    column:    4,
                    direction: 'desc',
                },
            ],
            columns:            [
                {
                    data:  'number',
                    name:  'bm_manufacturing_plant_transpositions.number',
                    title: 'form.field.tpcf-number',
                },
                {
                    data:  'concession_name',
                    name:  'concession_translations.description',
                    title: 'form.field.concession',
                },
                {
                    data:  'highway_name',
                    name:  'highway_translations.designation',
                    title: 'form.field.highway',
                },
                {
                    data:  'intervention_type_name',
                    name:  'intervention_type_translations.designation',
                    title: 'form.field.intervention-type',
                },
                {
                    data:  'lot_intervention_name',
                    name:  'lot_intervention_translations.designation',
                    title: 'form.field.lot',
                },
                {
                    data:  'date',
                    name:  'bm_manufacturing_plant_transpositions.date',
                    title: 'form.field.date',
                },
                {
                    data:  'material_type_name',
                    name:  'material_type_translations.name',
                    title: 'form.field.material-type',
                },
                {
                    data:  'layer_name',
                    name:  'bm_layer_translations.name',
                    title: 'form.field.layer-name',
                },
                {
                    data:  'application_name',
                    name:  'bituminous_application_translations.name',
                    title: 'form.field.application',
                },
                {
                    data:  'formulation_study_name',
                    name:  'bm_formulation_study_translations.designation',
                    title: 'form.field.associated-study-number',
                },
                {
                    data:  'supplier_name',
                    name:  'bm_manufacturing_plant_transpositions.supplier',
                    title: 'form.field.supplier',
                },
                {
                    data:  'central_type_name',
                    name:  'bm_manufacturing_plant_transpositions.central_type',
                    title: 'form.field.central-type',
                },
                {
                    data:  'central_location',
                    name:  'bm_manufacturing_plant_transpositions.central_location',
                    title: 'form.field.central-location',
                },
                {
                    data:  'observations',
                    name:  'bm_manufacturing_plant_transpositions.observations',
                    title: 'form.field.observations',
                },
                {
                    data:        'fractions',
                    name:        'fractions',
                    title:       'form.title.fractions',
                    type:        'custom-cell',
                    searchable:  false,
                    orderable:   false,
                    customStyle: 'max-width: 400px',
                    headers:     [
                        {
                            index: 'sample_number',
                            label: 'form.field.sample-number',
                        },
                        {
                            index: 'fraction',
                            label: 'form.field.fraction(d/D)',
                        },
                        {
                            index: 'percentage',
                            label: 'form.field.percentage',
                        },
                    ],
                    viewModel:   PLATFORM.moduleName('modules/core/custom-listing-cells/nested-table-cell/index'),
                },

                {
                    data:       'fillers',
                    name:       'fillers',
                    title:      'form.title.other-components',
                    type:       'custom-cell',
                    searchable: false,
                    orderable:  false,
                    headers:    [
                        {
                            index:       'value',
                            customStyle: 'font-weight: bold; background-color: #eee;',
                        },
                        {
                            index:       'percentage',
                            customStyle: 'min-width: 80px',
                        },
                    ],
                    viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/nested-table-cell/index'),
                },
                {
                    data:       'aggregates_lab_test',
                    name:       'aggregates_lab_test',
                    title:      'form.field.sample-number',
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:       'entity_name',
                    name:       'entity_translations.name',
                    title:      'form.field.entity',
                    searchable: false,
                    orderable:  false,
                    type:       'processed',
                    processor:  (row) => this.getEntityWithTeam(row),
                },
                {
                    data:  'created_by',
                    name:  'user_entity_team_lots.identifier',
                    title: 'form.field.created-by',
                    type:  'userEntityTeamLot',
                },
                {
                    data:            'created_at',
                    name:            'bm_manufacturing_plant_transpositions.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:          'is_validated',
                    name:          'is_validated',
                    title:         'form.field.validated',
                    type:          'custom-cell',
                    fullDataModel: 'validator',
                    viewModel:     PLATFORM.moduleName('modules/core/custom-listing-cells/checkmark/index'),
                    orderable:     false,
                    searchable:    false,
                },
                {
                    data:       'additional_info',
                    name:       'additional_info',
                    title:      'form.title.additional-info',
                    type:       'custom-cell',
                    icon:       'icon-info3',
                    show:       (row) => row.has_additional_information,
                    action:     (row) => this.openModal(row.id, ManufacturingPlantTranspositionAdditionalInformationsModal),
                    viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/action-icon/index'),
                    orderable:  false,
                    searchable: false,
                },
            ],
        };
    }

    /**
     * Executes a given action if it is selected at least one row
     *
     * @param action
     * @returns {*}
     */
    ensureSelectedRows(action) {
        if (!this.datatable.instance.selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        action();
    }

    /**
     * Call promise then show response notice and reload datatable
     * @param call
     */
    callThenResponse(call) {
        call.then(response => {
            this.appContainer.notifier.responseNotice(response);
            this.datatable.instance.reload();
        });
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }

    /**
     * Exports the listing to Excel
     * Assumptions: a `filterModel`, `downloader` instance and repository `export` method must exist
     *
     * @param filename
     * @param selectedRows
     */
    exportListing(filename = 'listing', selectedRows = null) {
        const filterModel = Object.assign({
            sorting: this.datatable.instance.getSortingColumn(),
        }, this.filterModel);

        if (selectedRows) {
            filterModel.selectedRows = selectedRows;
        }

        this.repository.exportManufacturingPT(filterModel).then(response => this.downloader.download(response, filename, 'xlsx'));
    }

    /**
     * Processed cell for Entity (Team)
     *
     * @param row
     * @returns {*}
     */
    getEntityWithTeam(row) {

        let html = row['entity_name'];
        html += '<small><br />(';
        html += row['team_name'];
        html += ')</small>';

        return html;
    }
}

