import { inject }            from 'aurelia-framework';
import { PLATFORM }          from 'aurelia-pal';
import { BaseFormViewModel } from 'base-form-view-model';
import { FormSchema }        from 'modules/bituminous-mixtures/mixtures/bm-tests/form-schema';
import { BMTestsRepository } from 'modules/bituminous-mixtures/mixtures/bm-tests/services/repository';
import { CommentFormSchema } from 'modules/core/custom-components/comments/form-schema';
import { AppContainer }      from 'resources/services/app-container';

@inject(AppContainer, BMTestsRepository, FormSchema, CommentFormSchema)
export class CreateBMTest extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';

    alert   = {};
    model   = {};
    warning = {};
    tabs    = {};
    create  = true;

    constructor(appContainer, repository, formSchema, commentSchema) {
        super(appContainer);
        this.repository    = repository;
        this.formSchema    = formSchema;
        this.commentSchema = commentSchema;

    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/bm-tests/form.html');
    }

    /**
     * Handles canActivate event
     */
    async canActivate() {
        this.repository.setType(this.type);
        this.routePrefix      = `bituminous-mixtures.mixtures.${this.type}`;
        this.permissionPrefix = this.routePrefix;
        this.formId           = `${this.permissionPrefix}.creation-form`;

        this.backRoute = `${this.routePrefix}.index`;

        if (typeof this.type === 'undefined' || typeof this.sectorId === 'undefined') {
            console.warn('BM Test type and sector are undefined');
            return false;
        }

        return this.redirectToSelectLotIntervention(`${this.routePrefix}.create`) && super.can([
            this.permissionPrefix + '.manage',
            this.permissionPrefix + '.create',
        ]);
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.initializeModel();

        // Fetch the next number and selected lot information
        const selectedLotInformation = this.appContainer.authenticatedUser.getAllSelectedLotInformation();

        // Assign base lot information
        this.assignBaseLotInformation(selectedLotInformation);

        // Get schema for each tab
        this.tabs.selectBulletins       = await this.formSchema.selectBulletinsSchema.schema(this);
        this.tabs.general               = this.formSchema.generalSchema.schema(this);
        this.tabs.general.lotInfoSchema = this.formSchema.lotInfoSchema(this);
        this.tabs.harvestData           = await this.formSchema.harvestDataSchema.schema(this);
        this.tabs.additionalInfo        = this.formSchema.additionalInfoSchema.schema(this);
        this.globalSchema               = this.formSchema.globalSchema(this);
        this.commentSchema              = this.commentSchema.schema(this);
    }

    initializeModel() {
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        this.initialModel.comment = this.commentSchema.model();
        this.model.comment        = this.commentSchema.model();
    }

    /**
     * Assigns base lot information to the model
     *
     * @param selectedLotInformation
     */
    assignBaseLotInformation(selectedLotInformation) {
        const baseInfo = {
            entity_name:            selectedLotInformation.entity_name,
            entity_id:              selectedLotInformation.entity_id,
            team_name:              selectedLotInformation.team_name,
            team_id:                selectedLotInformation.team_id,
            highway_name:           selectedLotInformation.highway_name,
            highway_id:             selectedLotInformation.highway_id,
            concession_name:        selectedLotInformation.concession_name,
            concession_id:          selectedLotInformation.concession_id,
            intervention_type_name: selectedLotInformation.lot_intervention_type_name,
            intervention_type_id:   selectedLotInformation.lot_intervention_type_id,
            lot_intervention_name:  selectedLotInformation.lot_intervention_name,
            lot_intervention_id:    selectedLotInformation.lot_intervention_id,
        };

        this.initialModel.assign(baseInfo);
        this.model.assign(baseInfo);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.sectorId, this.model)
            .then((response) => {
                this.handleResponse(response, {
                    name:  `${this.routePrefix}.edit`,
                    params: { id: response.model ? response.model.id : null },
                });
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }
}
