import { inject }                  from 'aurelia-framework';
import { BaseListViewModel }       from 'base-list-view-model';
import { NotificationsRepository } from 'modules/personal-area/notifications/services/repository';
import { FilterFormSchema }        from 'modules/personal-area/notifications/filter-form-schema';
import { AppContainer }            from 'resources/services/app-container';

@inject(AppContainer, NotificationsRepository, FilterFormSchema)
export class ListNotifications extends BaseListViewModel {

    listingId   = 'personal-area-notifications-listing';
    headerTitle = 'listing.personal-area.notifications';

    repository;
    datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     */
    constructor(appContainer, repository, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'personal-area.notifications.manage',
            'personal-area.notifications.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            edit:            null,
            destroy:         null,
            destroySelected: false,
            actions:         [
                {
                    icon:    'icon-file-eye',
                    action:  (row) => this.viewNotificationHandler(row),
                    tooltip: 'form.button.view',
                },
                {
                    icon:    'icon-file-text',
                    action:  (row) => this.viewNotificationInContextHandler(row),
                    tooltip: 'form.button.view-in-context',
                    visible: (row) => row.dismissed_at === null && row.relatable && row.route,
                },
                {
                    icon:    'icon-check',
                    action:  (row) => this.dismissTaskHandler(row),
                    tooltip: 'form.button.dismiss',
                    visible: (row) => row.dismissed_at === null,
                },
            ],
            options:         [],
            buttons:         [],
            selectable:      false,
            sorting:         {
                column:    2,
                direction: 'desc',
            },
            columns:         [
                {
                    data:  'type',
                    name:  'notification_types.name',
                    title: 'form.field.type',
                },
                {
                    data:  'subject',
                    name:  'notifications.subject',
                    title: 'form.field.subject',
                },
                {
                    data:            'created_at',
                    name:            'notifications.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        { name: 'dateTimeFormat' },
                    ],
                },
                {
                    data:  'deadline',
                    name:  'notifications.deadline',
                    title: 'form.field.deadline',
                },
                {
                    data:  'status',
                    name:  'notification_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

    /**
     * Handles view action button
     *
     * @param row
     */
    viewNotificationHandler(row) {
        // marks the notification has read
        this.repository.read(row.id).then(() => {
            let route = this.appContainer.router.generate('personal-area.notifications.details', {id: row.id});

            this.appContainer.router.navigate(route);
        });
    }

    /**
     * Handles view action button
     *
     * @param row
     */
    viewNotificationInContextHandler(row) {
        if (row.relatable && row.route) {
            let route = this.appContainer.router.generate(row.route, {id: row.relatable.id});

            // marks the notification has read
            this.repository.read(row.id).then(() => {
                this.appContainer.router.navigate(route);
            });
        }
    }

    /**
     * Handles dismiss action button
     *
     * @param row
     */
    dismissTaskHandler(row) {
        this.repository
            .dismiss(row.id)
            .then((response) => {
                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    this.datatable.instance.reload();
                } else {
                    this.appContainer.notifier.successNotice(response.message);
                }
            });
    }

}
