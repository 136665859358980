import { DialogController, DialogService } from 'aurelia-dialog';
import { bindable, inject }                from 'aurelia-framework';
import { FormSchema }                      from 'modules/administration/files/form-schema';
import { ListFiles }                       from 'modules/administration/files/index';
import { AppContainer }                    from 'resources/services/app-container';
import { Downloader }                      from 'resources/services/downloader';
import { FilesRepository }                 from './services/repository';

@inject(AppContainer, FilesRepository, Downloader, DialogController, DialogService, FormSchema)
export class ListFilesModal extends ListFiles {

    @bindable settings = {
        size:    'modal-lg',
        buttons: false,
    };

    creationSchemaVisible = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param downloader
     * @param dialogController
     * @param dialogService
     * @param formSchema
     */
    constructor(appContainer, repository, downloader, dialogController, dialogService, formSchema) {
        super(appContainer, downloader, formSchema);

        this.dialogController = dialogController;
    }

    /**
     * Handles activate event
     *
     * @param params
     */
    activate(params) {
        this.relatableRepository     = params.relatableRepository;
        this.relatableModel          = params.relatableModel;
        this.relatableModelNameField = params.relatableModelNameField;
        this.allowedMimeTypes        = params.allowedMimeTypes;
        this.hideName                = params.hideName;
        this.readonly                = params.readonly;
        this.info                    = params.info;
    }

    /**
     * Handles attached event
     */
    attached() {
        if (this.relatableModel && this.relatableModelNameField && this.relatableModel[this.relatableModelNameField]) {
            return this.settings.title = this.appContainer.i18n.tr('form.title.identified-files-listing', {
                description: this.relatableModel[this.relatableModelNameField],
            });
        }

        return this.settings.title = this.appContainer.i18n.tr('form.title.files-listing');
    }

}
