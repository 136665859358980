import { bindable, inject }         from 'aurelia-framework';
import { I18N }                     from 'aurelia-i18n';
import { BaseListViewModel }        from 'base-list-view-model';
import { FilterFormSchema }         from 'modules/administration/resource-fields/filter-form-schema';
import { ResourceFieldsRepository } from 'modules/administration/resource-fields/services/repository';
import { AppContainer }             from 'resources/services/app-container';
import { Downloader }               from 'resources/services/downloader';

@inject(AppContainer, ResourceFieldsRepository, I18N, Downloader, FilterFormSchema)
export class ListResourceFields extends BaseListViewModel {

    listingId = 'administration-resource-fields-listing';

    @bindable headerTitle = 'listing.administration.helping-system';

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.resource-fields.manage',
            'administration.resource-fields.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('administration.helping-system.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['administration.resource-fields.manage', 'administration.resource-fields.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('administration.helping-system.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['administration.resource-fields.manage', 'administration.resource-fields.edit']),
            },
            destroy:         false,
            options:         [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.title.helping-system'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['administration.resource-fields.manage', 'administration.resource-fields.view']),
                },
            ],
            selectable:      true,
            destroySelected: false,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'resource_name',
                    name:  'resource_translations.name',
                    title: 'form.field.resource',
                },
                {
                    data:  'name',
                    name:  'resource_field_translations.name',
                    title: 'form.field.field',
                },
                {
                    data:  'helper',
                    name:  'resource_field_translations.helper',
                    title: 'form.field.helper',
                },
                {
                    data:  'updated_by_name',
                    name:  'updaters.name',
                    title: 'form.field.updated-by',
                },
                {
                    data:            'updated_at',
                    name:            'resource_fields.updated_at',
                    title:           'form.field.updated-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

}
