import { inject }                          from 'aurelia-framework';
import { PLATFORM }                        from 'aurelia-pal';
import { BaseFormViewModel }               from 'base-form-view-model';
import { FormSchema }                      from 'modules/management/specifications/specifications/form-schema';
import { SpecificationsRepository }        from 'modules/management/specifications/specifications/services/repository';
import { SpecificationRevisionFormSchema } from 'modules/management/specifications/specifications/specification-revisions/form-schema';
import { AppContainer }                    from 'resources/services/app-container';

@inject(AppContainer, SpecificationsRepository, FormSchema, SpecificationRevisionFormSchema)
export class CreateSpecification extends BaseFormViewModel {

    headerTitle  = 'form.title.create-new-record';
    formId       = 'management.specifications.specifications.creation-form';
    create       = true;
    concessionId = null;

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param revisionFormSchema
     */
    constructor(appContainer, repository, formSchema, revisionFormSchema) {
        super(appContainer);

        this.repository         = repository;
        this.formSchema         = formSchema;
        this.revisionFormSchema = revisionFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/specifications/specifications/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.specifications.manage',
            'management.specifications.specifications.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        this.initialModel.specification_revision = this.revisionFormSchema.model(0);
        this.model.specification_revision        = this.revisionFormSchema.model(0);
        this.revisionFormSchema                  = this.revisionFormSchema.schema(this);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model)
            .then((response) => {
                this.handleResponse(response, {
                    name:   'management.specifications.specifications.edit',
                    params: { id: response.model ? response.model.id : null },
                });
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

}
