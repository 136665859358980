import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class BaseRepository {

    baseUrl;

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Builds criteria as get params
     *
     * @param {*} criteria
     *
     * @return string
     */
    buildCriteriaAsGetParams(criteria) {
        let params = [];

        for (let name in criteria) {
            params.push(name + '=' + ((typeof criteria[name] === 'string') ? criteria[name] : JSON.stringify(criteria[name])));
        }

        return params.join('&');
    }

    /**
     * Returns all records
     *
     * @returns {*}
     */
    all() {
        return this.httpClient.get(`${this.baseUrl}/all`);
    }

    /**
     * Returns all validated records
     *
     * @returns {*}
     */
    validated() {
        return this.httpClient.get(`${this.baseUrl}/validated`);
    }

    /**
     * Returns active records
     *
     * @returns {*}
     */
    active() {
        return this.httpClient.get(`${this.baseUrl}/active`);
    }

    /**
     * Performs a search given some criteria, returns datatable
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post(`${this.baseUrl}/search`, criteria);
    }

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post(`${this.baseUrl}`, data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`${this.baseUrl}/${id}`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`${this.baseUrl}/${id}`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`${this.baseUrl}/${id}`);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete(`${this.baseUrl}/destroy-selected`, { ids: ids });
    }

    /**
     * Exports a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    export(criteria) {
        return this.httpClient.rawPost(`${this.baseUrl}/export`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

    /**
     * Exports to pdf given some criteria
     *
     * @param id
     *
     * @param criteria
     * @returns {*}
     */
    exportToPdf(id, criteria) {
        return this.httpClient.rawPost(`${this.baseUrl}/${id}/export-to-pdf`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

    /**
     * Generates a zip with records' bulletin files
     *
     * @returns {*}
     */
    generateZip(criteria) {
        return this.httpClient.post(`${this.baseUrl}/generate-zip`, criteria);
    }

    /**
     * Closes a given record
     *
     * @param id
     * @return {*}
     */
    validate(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/validate`);
    }

    /**
     * Reopens a given record
     *
     * @param id
     * @return {*}
     */
    invalidate(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/invalidate`);
    }

    /**
     * Reopens selected records
     *
     * @param selectedRows
     *
     * @return {*}
     */
    invalidateSelected(selectedRows) {
        return this.httpClient.patch(`${this.baseUrl}/invalidate-selected`, { selectedRows });
    }

    /**
     * Closes selected records
     *
     * @param selectedRows
     *
     * @return {*}
     */
    validateSelected(selectedRows) {
        return this.httpClient.patch(`${this.baseUrl}/validate-selected`, { selectedRows });
    }

    /**
     * Fetches the next order number
     *
     * @return {*}
     */
    fetchNextOrderNumber() {
        return this.httpClient.get(`${this.baseUrl}/next-order-number`);
    }

    /**
     * Reorders fields
     *
     * @param data
     *
     * @returns {*}
     */
    updateOrder(data) {
        return this.httpClient.put(`${this.baseUrl}/reorder`, data);
    }
}
