import { inject }                                     from 'aurelia-framework';
import { AggControlTypesRepository }                  from 'modules/aggregates/lab/services/control-types-repository';
import { ApprovedWorkingFormulasRepository }          from 'modules/bituminous-mixtures/mixtures/approved-working-formulas/services/repository';
import { BMTestReferenceTypesRepository }             from 'modules/bituminous-mixtures/mixtures/bm-tests/services/reference-types-repository';
import { TestHeaderSchema }                           from 'modules/bituminous-mixtures/mixtures/bm-tests/tab-schemas/test-header-schema';
import { FormulationStudiesRepository }               from 'modules/bituminous-mixtures/mixtures/formulation-studies/services/repository';
import { ManufacturingPlantTranspositionsRepository } from 'modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/services/repository';
import { BituminousApplicationsRepository }           from 'modules/management/bituminous-mixtures/bituminous-applications/services/repository';
import { LayersRepository }                           from 'modules/management/bituminous-mixtures/layers/services/repository';
import { RevisionMaterialTypesRepository }            from 'modules/management/specifications/specifications/specification-revisions/specification-revision-material-types/services/repository';

@inject(RevisionMaterialTypesRepository, BituminousApplicationsRepository, AggControlTypesRepository, LayersRepository, ApprovedWorkingFormulasRepository, ManufacturingPlantTranspositionsRepository, FormulationStudiesRepository, BMTestReferenceTypesRepository)
export class GeneralSchema extends TestHeaderSchema {

    schemaSlug = 'general';

    constructor(materialTypesRepository, bitApplicationsRepository, controlTypesRepository, bmLayersRepository, approvedWorkingFormulasRepository, manufacturingPlantTranspositionsRepository, formulationStudiesRepository, referenceTypesRepository) {
        super();
        this.materialTypesRepository                    = materialTypesRepository;
        this.bitApplicationsRepository                  = bitApplicationsRepository;
        this.controlTypesRepository                     = controlTypesRepository;
        this.bmLayersRepository                         = bmLayersRepository;
        this.approvedWorkingFormulasRepository          = approvedWorkingFormulasRepository;
        this.manufacturingPlantTranspositionsRepository = manufacturingPlantTranspositionsRepository;
        this.formulationStudiesRepository               = formulationStudiesRepository;
        this.referenceTypesRepository                   = referenceTypesRepository;
    }

    /**
     * Returns form schema for "Select Tests" tab
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {

        super.schema(viewModel, readonly);

        this.reference_type_id = {
            type:         'select2',
            key:          'reference_type_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.sample-referring-to',
            remoteSource: () => this.referenceTypesRepository.all(),
            size:         10,
            required:     false,
            attributes:   {
                disabled: readonly,
            },
        };

        this.bm_approved_working_formula_id = {
            type:         'select2',
            key:          'bm_approved_working_formula_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.approved-working-formula-num',
            size:         10,
            remoteSource: () => this.approvedWorkingFormulasRepository.validatedByCurrentLotIntervention(),
            observers:    [
                (newValue) => {
                    this.bm_layer_id.instance.enable(!newValue);
                    this.material_type_id.instance.enable(!newValue);

                    if (newValue) {
                        this.manufacturingPlantTranspositionsRepository.validatedByApprovedWorkingFormulaId(newValue)
                            .then(response => {
                                if (!response) return;
                                viewModel.model.bm_manufacturing_plant_transposition_ids   = response.map(item => item.id);
                                viewModel.model.bm_manufacturing_plant_transposition_names = response.map(item => item.name).join('\n');
                            });

                        this.formulationStudiesRepository.findByApprovedWorkingFormulaId(newValue)
                            .then(response => {
                                if (!response) return;

                                this.bm_layer_id.instance.disable(response.length);
                                this.material_type_id.instance.disable(response.length);

                                viewModel.model.bm_layer_id      = response.bm_layer_id;
                                viewModel.model.material_type_id = response.material_type_id;

                                viewModel.model.bm_formulation_study_id   = response.id;
                                viewModel.model.bm_formulation_study_name = response.name;
                            });
                    } else {
                        viewModel.model.bm_manufacturing_plant_transposition_ids   = [];
                        viewModel.model.bm_manufacturing_plant_transposition_names = '';
                        viewModel.model.bm_formulation_study_id                    = null;
                        viewModel.model.bm_formulation_study_name                  = '';
                    }
                },
            ],
            required:     false,
            attributes:   {
                disabled: readonly,
            },
        }

        this.bm_manufacturing_plant_transposition_names = {
            type:       'textarea',
            key:        'bm_manufacturing_plant_transposition_names',
            label:      'form.field.material-plant-transposition-associated-number-abbreviated',
            size:       10,
            required:   false,
            attributes: {
                readonly: true,
                rows:     4,
            },
        };

        this.bm_formulation_study_name = {
            type:       'text',
            key:        'bm_formulation_study_name',
            label:      'form.field.associated-formulation-study-number',
            size:       10,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.material_type_id = {
            type:         'select2',
            key:          'material_type_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.material-type',
            remoteSource: () => this.materialTypesRepository.allFromSector(viewModel.sectorId, {
                lot_intervention_id: viewModel.model.lot_intervention_id,
                date:                viewModel.model.created_at ?? 'now',
            }),
            size:         6,
            required:     true,
            attributes:   {
                disabled: viewModel.model.bm_approved_working_formula_id || readonly,
            },
        };

        this.bm_layer_id = {
            type:         'select2',
            key:          'bm_layer_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.layer',
            remoteSource: () => this.bmLayersRepository.allForCurrentSpecification(),
            size:         6,
            required:     false,
            attributes:   {
                disabled: viewModel.model.bm_approved_working_formula_id || readonly,
            },
        };

        this.application_id = {
            type:         'select2',
            key:          'application_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.application',
            remoteSource: () => this.bitApplicationsRepository.all(),
            size:         10,
            required:     false,
            attributes:   {
                disabled: readonly,
            },
        };

        this.layer_thickness = {
            type:       'number',
            key:        'layer_thickness',
            label:      'form.field.layer-thickness-indicated(m)',
            size:       6,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        return viewModel.type === 'lab'
            ? [
                [this.number, this.date],
                [this.reference_type_id],
                [this.bm_approved_working_formula_id],
                [this.bm_manufacturing_plant_transposition_names],
                [this.bm_formulation_study_name],
                [this.material_type_id, this.bm_layer_id],
                [this.application_id],
                [this.external_reference, this.layer_thickness],
                [this.observations],
            ] : [
                [this.number, this.date],
                [this.bm_approved_working_formula_id],
                [this.bm_manufacturing_plant_transposition_names],
                [this.bm_formulation_study_name],
                [this.material_type_id, this.bm_layer_id],
                [this.application_id],
                [this.external_reference, this.layer_thickness],
                [this.observations],
            ];
    }

}
