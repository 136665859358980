import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class LotInterventionsRepository extends BaseRepository {
    baseUrl = 'management/concessions/concessions/lot-interventions';

    /**
     * Creates a record
     *
     * @param data
     *
     * @param interventionTypeId
     * @returns {*}
     */
    create(data, interventionTypeId) {
        return this.httpClient.post(`management/concessions/concessions/${interventionTypeId}/lot-interventions`, data);
    }

    /**
     * Returns all teams for a given lot intervention
     *
     * @param lotInterventionId
     * @return {*}
     */
    teams(lotInterventionId) {
        return this.httpClient.get(`${this.baseUrl}/${lotInterventionId}/teams`);
    }

    /**
     * Returns all lot intervention grouped
     *
     * @return {*}
     * @param criteria
     */
    groupedAll(criteria) {
        return this.httpClient.post(`${this.baseUrl}/grouped-all`, criteria);
    }

    /**
     * Returns all constructions for a given lot intervention
     *
     * @param lotInterventionId
     * @param constructionType
     * @return {*}
     */
    allConstructions(lotInterventionId, constructionType) {
        return this.httpClient.get(`${this.baseUrl}/${lotInterventionId}/constructions/${constructionType}`);
    }

    /**
     * Closes a lot intervention
     *
     * @param lotInterventionId
     * @return {*}
     */
    close(lotInterventionId) {
        return this.httpClient.patch(`${this.baseUrl}/${lotInterventionId}/close`);
    }

    /**
     * Reopens a lot intervention
     *
     * @param lotInterventionId
     * @return {*}
     */
    reopen(lotInterventionId) {
        return this.httpClient.patch(`${this.baseUrl}/${lotInterventionId}/reopen`);
    }

    /**
     * Returns all related lot interventions (are present in the same lot group)
     *
     * @param lotInterventionId
     * @return {*}
     */
    relatedTo(lotInterventionId) {
        return this.httpClient.get(`${this.baseUrl}/${lotInterventionId}/related`);
    }

}
