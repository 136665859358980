import { DialogService }                    from 'aurelia-dialog';
import { inject }                           from 'aurelia-framework';
import { FormulationStudy }                 from 'modules/bituminous-mixtures/models/formulation-study';
import { AdditionalInformationsRepository } from 'modules/management/additional-information/additional-informations/services/repository';
import { ViewModalAdditionalInformation }   from 'modules/management/additional-information/additional-informations/view/index-modal';
import { BituminousApplicationsRepository } from 'modules/management/bituminous-mixtures/bituminous-applications/services/repository';
import { LayersRepository }                 from 'modules/management/bituminous-mixtures/layers/services/repository';
import { StudyTypesRepository }             from 'modules/management/bituminous-mixtures/study-types/services/repository';
import { Sector }                           from 'modules/management/models/sector';
import { RevisionMaterialTypesRepository }  from 'modules/management/specifications/specifications/specification-revisions/specification-revision-material-types/services/repository';

@inject(StudyTypesRepository, RevisionMaterialTypesRepository, LayersRepository, BituminousApplicationsRepository, AdditionalInformationsRepository, DialogService)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param studyTypesRepository
     * @param materialTypesRepository
     * @param layersRepository
     * @param bituminousApplicationsRepository
     * @param additionalInformationRepository
     * @param dialogService
     */
    constructor(studyTypesRepository, materialTypesRepository, layersRepository, bituminousApplicationsRepository, additionalInformationRepository, dialogService) {
        this.studyTypesRepository             = studyTypesRepository;
        this.materialTypesRepository          = materialTypesRepository;
        this.layersRepository                 = layersRepository;
        this.bituminousApplicationsRepository = bituminousApplicationsRepository;
        this.additionalInformationRepository  = additionalInformationRepository;
        this.dialogService                    = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {FormulationStudy}
     */
    model() {
        let model = new FormulationStudy();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema for lot info
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    lotInfoSchema(viewModel, readonly = false) {
        this.concession_name = {
            type:       'text',
            key:        'concession_name',
            label:      'form.field.concession',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        this.entity_team_name = {
            type:       'text',
            key:        'entity_team_name',
            label:      'form.field.entity-team',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        this.highway_name = {
            type:       'text',
            key:        'highway_name',
            label:      'form.field.highway',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        this.intervention_type_name = {
            type:       'text',
            key:        'intervention_type_name',
            label:      'form.field.intervention-type',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        this.lot_intervention_name = {
            type:       'textarea',
            key:        'lot_intervention_name',
            label:      'form.field.lot-intervention',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        return [
            [this.concession_name, this.highway_name],
            [this.intervention_type_name, this.entity_team_name],
            [this.lot_intervention_name],
        ];
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.number = {
            type:       'int',
            key:        'number',
            label:      'form.field.study-number',
            size:       2,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.date = {
            type:       'material-ui-date-picker',
            key:        'date',
            label:      'form.field.date',
            size:       5,
            attributes: {
                disabled: readonly,
            },
        };

        this.approval_date = {
            type:       'material-ui-date-picker',
            key:        'approval_date',
            label:      'form.field.approval-date',
            size:       5,
            attributes: {
                disabled: readonly,
            },
        };

        this.study_type_id = {
            type:         'select2',
            key:          'study_type_id',
            label:        'form.field.study-type',
            size:         6,
            remoteSource: () => this.studyTypesRepository.byLotIntervention(viewModel.model.lot_intervention_id),
            attributes:   {
                disabled: readonly,
            },
            observers:    [
                async (newValue) => {
                    viewModel.model.properties = [];

                    if (newValue) {
                        const properties = await this.studyTypesRepository.properties(newValue);
                        if (properties.length > 0) {
                            viewModel.properties = properties.map((property) => {
                                return {
                                    property_id: property.id,
                                    name:        property.name,
                                    unit:        property.unit,
                                };
                            });

                            viewModel.propertiesInfo = {};
                        } else {
                            viewModel.properties     = [];
                            viewModel.propertiesInfo = viewModel.infoAlertMessage(viewModel.appContainer.i18n.tr('form.message.no-properties'));
                        }

                        viewModel.warning = {};
                    } else {
                        viewModel.propertiesInfo = {};
                    }
                },
            ],
        };

        this.material_type_id = {
            type:         'select2',
            key:          'material_type_id',
            label:        'form.field.material-type',
            size:         6,
            remoteSource: () => this.materialTypesRepository.allFromSector(Sector.BITUMINOUS_MIXTURES_FORMULATION_STUDY, {
                lot_intervention_id: viewModel.model.lot_intervention_id,
                date:                viewModel.model.created_at ?? 'now',
            }),
            attributes:   {
                disabled: readonly,
            },
            observers:    [
                (newValue, oldValue) => {
                    this.layer_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.layersRepository.materialTypeLayer(newValue).then((response) => {
                            this.layer_id.options = response;
                            this.layer_id.instance.enable(response.length);

                            if (newValue !== viewModel.model.material_type_id || Number(newValue) !== oldValue) {
                                viewModel.model.layer_id = response.length ? response[0].id : null;
                            }
                        });
                    }
                },
            ],
        };

        this.layer_id = {
            type:         'select2',
            key:          'layer_id',
            label:        'form.field.layer',
            size:         6,
            remoteSource: () => viewModel.model.material_type_id
                ? this.layersRepository.materialTypeLayer(viewModel.model.material_type_id)
                : Promise.resolve([{}]),
            required:     false,
            attributes:   {
                disabled: readonly || !viewModel.model.material_type_id,
            },
        };

        this.application_id = {
            type:         'select2',
            key:          'application_id',
            label:        'form.field.application',
            size:         6,
            remoteSource: () => this.bituminousApplicationsRepository.all(),
            required:     false,
            attributes:   {
                disabled: readonly,
            },
        };

        this.designation = {
            type:       'text',
            key:        'designation',
            label:      'form.field.mixture-designation',
            size:       12,
            attributes: {
                disabled: readonly,
            },
        };

        this.observations = {
            type:       'textarea',
            key:        'observations',
            label:      'form.field.observations',
            size:       12,
            required:   false,
            attributes: {
                disabled: readonly,
            },
        };

        const schema = [
            [this.number, this.date, this.approval_date],
            [this.designation],
            [this.study_type_id, this.material_type_id],
            [this.layer_id, this.application_id],
            [this.observations],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                required:   false,
                attributes: {
                    disabled: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                required:   false,
                attributes: {
                    disabled: true,
                },
            };

            this.validated_by = {
                type:       'text',
                key:        'validated_by',
                label:      'form.field.validated-by',
                size:       6,
                required:   false,
                hidden:     !viewModel.model.is_validated,
                attributes: {
                    disabled: true,
                },
            };

            this.validated_at = {
                type:       'text',
                key:        'validated_at',
                label:      'form.field.validated-at',
                size:       6,
                required:   false,
                hidden:     !viewModel.model.is_validated,
                attributes: {
                    disabled: true,
                },
            };

            return [...schema, [this.created_by, this.created_at], [this.validated_by, this.validated_at]];
        }

        return schema;
    }

    /**
     * Returns form schema for additional information
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    additionalInformationSchema(viewModel, readonly = false) {
        this.additional_informations = {
            type:              'duallistbox',
            key:               'additional_informations',
            label:             'form.field.select-additional-information',
            required:          false,
            remoteSource:      () => this.additionalInformationRepository.searchMap(),
            handleDoubleClick: (selectedOption) => {
                if (selectedOption.id) {
                    this.dialogService
                        .open({
                            viewModel: ViewModalAdditionalInformation,
                            model:     { id: selectedOption.id },
                        });
                }
            },
            size:              12,
            attributes:        {
                disabled: readonly,
            },
        };

        return [
            [this.additional_informations],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('bituminous-mixtures.mixtures.formulation-studies.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
