import { DialogController }                 from 'aurelia-dialog';
import { bindable, inject }                 from 'aurelia-framework';
import { PLATFORM }                         from 'aurelia-pal';
import { FormSchema }                       from 'modules/management/additional-information/additional-informations/form-schema';
import { AdditionalInformationsRepository } from 'modules/management/additional-information/additional-informations/services/repository';
import { ViewAdditionalInformation }        from 'modules/management/additional-information/additional-informations/view/index';
import { AppContainer }                     from 'resources/services/app-container';

@inject(AppContainer, AdditionalInformationsRepository, FormSchema, DialogController)
export class ViewModalAdditionalInformation extends ViewAdditionalInformation {

    @bindable settings = {
        title:   'form.title.view-record',
        size:    'modal-lg',
        buttons: false,
    };

    model = {};

    constructor(appContainer, repository, formSchema, dialogController) {
        super(appContainer, repository, formSchema);

        this.dialogController = dialogController;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/additional-information/additional-informations/view/form-modal.html');
    }

    /**
     * Handles activate event
     *
     * @param params
     */
    async activate(params) {
        await super.activate(params);

        if (this.model && this.model.display_name) {
            this.settings.title = this.appContainer.i18n.tr('form.field.aditional-information') + ' | ' + this.model.display_name;
        }

        this.formSchema.backButton.action = () => {
            return this.dialogController.ok(params);
        }

        if (this.model.existing_file === null) this.formSchema.viewFileButton.hidden = true;
    }
}
