import { inject }                         from 'aurelia-framework';
import { AdditionalInfoSchema }           from 'modules/aggregates/lab/tab-schemas/additional-info-schema';
import { GeneralSchema }                  from 'modules/aggregates/lab/tab-schemas/general-schema';
import { MaterialCharacterizationSchema } from 'modules/aggregates/lab/tab-schemas/material-characterization-schema';
import { SelectBulletinsSchema }          from 'modules/aggregates/lab/tab-schemas/select-bulletins-schema';
import { WorkControlSchema }              from 'modules/aggregates/lab/tab-schemas/work-control-schema';
import { AggregatesLabTest }              from 'modules/aggregates/models/aggregates-lab-test';

@inject(SelectBulletinsSchema, GeneralSchema, WorkControlSchema, MaterialCharacterizationSchema, AdditionalInfoSchema)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {};

    constructor(selectBulletinsSchema, generalSchema, workControlSchema, materialCharacterizationSchema, additionalInfoSchema) {
        this.selectBulletinsSchema          = selectBulletinsSchema;
        this.generalSchema                  = generalSchema;
        this.workControlSchema              = workControlSchema;
        this.materialCharacterizationSchema = materialCharacterizationSchema;
        this.additionalInfoSchema           = additionalInfoSchema;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {AggregatesLabTest}
     */
    model() {
        let model = new AggregatesLabTest();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    lotInfoSchema(viewModel) {

        this.concession_name = {
            type:       'text',
            key:        'concession_name',
            label:      'form.field.concession',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.entity_name = {
            type:       'text',
            key:        'entity_name',
            label:      'form.field.entity',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.highway_name = {
            type:       'text',
            key:        'highway_name',
            label:      'form.field.highway',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.intervention_type_name = {
            type:       'text',
            key:        'intervention_type_name',
            label:      'form.field.intervention-type',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.lot_intervention_name = {
            type:       'text',
            key:        'lot_intervention_name',
            label:      'form.field.lot-intervention',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        return [
            [this.concession_name, this.entity_name],
            [this.highway_name, this.intervention_type_name],
            [this.lot_intervention_name],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute(
                {
                    name:   viewModel.backRoute,
                    params: viewModel.backRouteParams,
                }),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
