import { DialogService }                from 'aurelia-dialog';
import { bindable, inject }             from 'aurelia-framework';
import { I18N }                         from 'aurelia-i18n';
import { BaseListViewModel }            from 'base-list-view-model';
import { BulletinFieldsBulletinsModal } from 'modules/management/specifications/bulletin-fields/bulletins-modal';
import { FilterFormSchema }             from 'modules/management/specifications/bulletin-fields/filter-form-schema';
import { AppContainer }                 from 'resources/services/app-container';
import { Downloader }                   from 'resources/services/downloader';
import { BulletinFieldsRepository }     from './services/repository';

@inject(AppContainer, DialogService, BulletinFieldsRepository, I18N, Downloader, FilterFormSchema)
export class ListBulletinFields extends BaseListViewModel {

    listingId = 'management-specifications-bulletin-fields-listing';

    @bindable headerTitle    = 'listing.management.specifications.bulletin-fields';
    @bindable newRecordRoute = 'management.specifications.bulletin-fields.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, dialogService, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.dialogService    = dialogService;
        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.bulletin-fields.manage',
            'management.specifications.bulletin-fields.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.specifications.bulletin-fields.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.bulletin-fields.manage', 'management.specifications.bulletin-fields.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.specifications.bulletin-fields.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.bulletin-fields.manage', 'management.specifications.bulletin-fields.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => !row.slug && this.appContainer.authenticatedUser.can(['management.specifications.bulletin-fields.manage', 'management.specifications.bulletin-fields.delete']),
            },
            options:         [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.bulletin-fields'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.bulletin-fields.manage', 'management.specifications.bulletin-fields.view']),
                },
            ],
            actions:         [
                {
                    icon:    'icon-file-spreadsheet',
                    tooltip: 'form.field.bulletins',
                    action:  (row) => this.openModal(row.id, BulletinFieldsBulletinsModal),
                    visible: (row) => row.hasBulletins && this.appContainer.authenticatedUser.can([
                        'management.specifications.bulletins.manage',
                        'management.specifications.bulletins.edit',
                    ]),
                },
            ],
            selectable:      true,
            destroySelected: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'description',
                    name:  'bulletin_field_translations.description',
                    title: 'form.field.description',
                },
                {
                    data:  'observations',
                    name:  'bulletin_fields.observations',
                    title: 'form.field.observations',
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'bulletin_fields.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }
}
