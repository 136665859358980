import { bindable, inject } from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';

@inject(AppContainer)
export class RecursiveMenu {
    @bindable menu;
    @bindable parentName;
    @bindable disabled;
    @bindable permissionActions;
    @bindable permissions;
    @bindable permissionIdByResourcePermissionsAndAction;

    allSelectedActions = [];

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        this.appContainer = appContainer;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.allSelectedActions = [...this.permissionActions].reduce((selected, action) => {
            if (this.areAllSelectedForAction(action)) {
                selected.push(action.id);
            }

            return selected;
        }, []);
    }

    /**
     * Gets full name
     *
     * @return {string|*}
     */
    get fullName() {
        return this.parentName ? `${this.parentName} » ${this.menu.name}` : this.menu.name;
    }

    /**
     * Updates selected all actions
     */
    updateSelectedAllActions() {
        return [...this.permissionActions].forEach(action => {
            const index = this.allSelectedActions.indexOf(action.id);
            if (this.areAllSelectedForAction(action) && index === -1) {
                this.allSelectedActions.push(action.id);
            } else if (!this.areAllSelectedForAction(action) && index !== -1) {
                this.allSelectedActions.splice(index, 1);
            }
        });
    }

    /**
     * Checks if all are selected for action
     *
     * @param action
     * @return {*|boolean}
     */
    areAllSelectedForAction(action) {
        const applicableResources = this.menu.resources
            .filter(r => this.permissionIdByResourcePermissionsAndAction(r.permissions, action.id) !== null);
        if (applicableResources.length === 0) return false;

        return applicableResources.every(resource => {
            const permissionId = this.permissionIdByResourcePermissionsAndAction(resource.permissions, action.id);
            return this.permissions.includes(permissionId);
        });
    }

    /**
     * Checks if action is disabled
     *
     * @param action
     * @return {boolean}
     */
    isActionDisabled(action) {
        const applicableResources = this.menu.resources
            .filter(r => this.permissionIdByResourcePermissionsAndAction(r.permissions, action.id) !== null);
        return applicableResources.length === 0;
    }

    /**
     * Toggles permission
     *
     * @param permissionId
     * @param action
     * @param checked
     */
    togglePermission(permissionId, action, checked) {
        if (permissionId === null) return;
        const index = this.permissions.indexOf(permissionId);

        if (checked && index === -1) {
            this.permissions.push(permissionId);
        } else if (!checked && index !== -1) {
            this.permissions.splice(index, 1);
        }

        this.updateSelectedAllActions();
    }

    /**
     * Toggles all for action
     *
     * @param action
     * @param checked
     */
    toggleAllForAction(action, checked) {
        for (const resource of this.menu.resources) {
            const permissionId = this.permissionIdByResourcePermissionsAndAction(resource.permissions, action.id);
            if (permissionId === null) continue;

            const index = this.permissions.indexOf(permissionId);
            if (checked && index === -1) {
                this.permissions.push(permissionId);
            } else if (!checked && index !== -1) {
                this.permissions.splice(index, 1);
            }
        }
    }
}
