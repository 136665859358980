import { BindingEngine }                 from 'aurelia-binding';
import { bindable, bindingMode, inject } from 'aurelia-framework';

@inject(Element, BindingEngine)
export class InputCheckbox {

    @bindable checkboxElement;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) checked;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) model;
    @bindable extraClasses;
    @bindable attributes;
    @bindable labelStyles;
    @bindable containerClasses = 'form-check';
    @bindable containerStyles;

    /**
     * Constructor
     *
     * @param element
     * @param bindingEngine
     */
    constructor(element, bindingEngine) {
        this.element       = element;
        this.bindingEngine = bindingEngine;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.subscribeObservers();
    }

    /**
     * Handles unbind event
     */
    unbind() {
        this.subscription.dispose();
    }

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        let observerMethod = isArray(this.checked) ? 'collectionObserver' : 'propertyObserver';
        let observable     = isArray(this.checked) ? [this.checked] : [this, 'checked'];

        this.subscription = this.bindingEngine[observerMethod](...observable).subscribe(() => $.uniform.update(this.checkboxElement));
    }

    get isChecked() {
        if (this.checkboxElement) {
            return this.checkboxElement.checked === true;
        }

        return false;
    }
}
