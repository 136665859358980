export class BMApplicationLocationsCustomCell {

    /**
     * headers must be an array containing desired index and label, e.g.:
     * headers = [
     *     {
     *         index: 'sample_number',
     *         label: 'form.field.sample-number',
     *     },
     *     {
     *         index: 'percentage',
     *         label: 'form.field.percentage',
     *     },
     * ]
     *
     * headers' indexes will be used to show data from the sourceData array
     *
     * Example of sourceData:
     *
     * sourceData = [
     *      [
     *          'sample_number'  => "12 - Agregados - Ascendi - etc.",
     *          'percentage'     => "77",
     *      ],
     *      [
     *          'sample_number'  => "15 - Agregados - Afaplan - etc.",
     *          'percentage'     => "70",
     *      ],
     * ];
     *
     */

    activate(model) {
        this.column  = model.column;
        this.row     = model.row;
        this.headers = this.column.headers;

        // Call source data if it's a function, otherwise get row data for default column data
        this.sourceData = this.column.sourceData instanceof Function
            ? this.column.sourceData(this.row)
            : this.row[this.column.data];

        // Check if embedded headers option is active
        this.embeddedHeaders = this.column.embeddedHeaders === true;
        if (this.embeddedHeaders && this.sourceData) {
            this.headers    = this.sourceData.labels;
            this.sourceData = this.sourceData.data;
        }

    }
}
