import { DialogService }                from 'aurelia-dialog';
import { inject }                       from 'aurelia-framework';
import { ExternalBranchesListingModal } from 'modules/core/external-tables/branches/listings-modal';
import { PkTypesRepository }            from 'modules/management/concessions/concessions-tree/pk-types/services/repository';
import { ConstructionsRepository }      from 'modules/management/concessions/constructions/services/repository';
import { ConstructionType }             from 'modules/management/models/construction-type';
import { OEData }                       from 'modules/management/models/oe-data';

@inject(DialogService, PkTypesRepository, ConstructionsRepository)
export class OEDataFormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        bulletins: [],
    };

    /**
     * Constructor
     *
     * @param dialogService
     * @param pkTypesRepository
     * @param constructionsRepository
     */
    constructor(dialogService, pkTypesRepository, constructionsRepository) {
        this.dialogService           = dialogService;
        this.pkTypesRepository       = pkTypesRepository;
        this.constructionsRepository = constructionsRepository
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {OEData}
     */
    model() {
        let model = new OEData();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {

        this.pks = {
            type:     'field-group',
            label:    'form.field.pk-start',
            key:      'pks',
            size:     6,
            fields:   [
                {
                    type:            'text',
                    key:             'pks_s',
                    showLabel:       false,
                    required:        false,
                    size:            2,
                    observers:       [
                        (newValue) => newValue.length === 3 && this.focusField('pks_f'),
                    ],
                    valueConverters: [
                        { name: 'numbersOnly' },
                    ],
                    attributes:      {
                        maxlength: 3,
                        readonly:  readonly,
                    },
                },
                {
                    type:  'divider',
                    label: '+',
                    size:  '10p',
                },
                {
                    type:            'text',
                    key:             'pks_f',
                    showLabel:       false,
                    required:        false,
                    size:            3,
                    observers:       [
                        (newValue) => newValue.length === 6 && this.focusField('pkf_s'),
                    ],
                    valueConverters: [
                        { name: 'autoDecimalValue' },
                    ],
                    attributes:      {
                        maxlength: 6,
                        readonly:  readonly,
                    },
                },
            ],
        }

        this.pkf = {
            type:     'field-group',
            label:    'form.field.pk-finish',
            key:      'pkf',
            size:     6,
            fields:   [
                {
                    type:            'text',
                    key:             'pkf_s',
                    showLabel:       false,
                    required:        false,
                    size:            2,
                    observers:       [
                        (newValue) => newValue.length === 3 && this.focusField('pkf_f'),
                    ],
                    valueConverters: [
                        { name: 'numbersOnly' },
                    ],
                    attributes:      {
                        maxlength: 3,
                        readonly:  readonly,
                    },
                },
                {
                    type:  'divider',
                    label: '+',
                    size:  '10p',
                },
                {
                    type:            'text',
                    key:             'pkf_f',
                    showLabel:       false,
                    required:        false,
                    size:            3,
                    valueConverters: [
                        { name: 'autoDecimalValue' },
                    ],
                    attributes:      {
                        maxlength: 6,
                        readonly:  readonly,
                    },
                },
            ],
        }

        this.pk_type_id = {
            type:         'select2',
            key:          'pk_type_id',
            label:        'form.field.pk-type',
            size:         6,
            remoteSource: () => this.pkTypesRepository.all(),
            attributes:   {
                disabled: readonly,
            },
        };

        this.pk_type_code = {
            type:       'text',
            key:        'pk_type_code',
            label:      'form.field.pk-type-code',
            size:       6,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.number_of_lanes = {
            type:       'number',
            key:        'number_of_lanes',
            label:      'form.field.number-of-lanes',
            size:       6,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.axis_id = {
            type:         'select2',
            key:          'axis_id',
            label:        'form.field.axis',
            size:         6,
            remoteSource: () => this.constructionsRepository.allByType(ConstructionType.OE_AXIS),
            attributes:   {
                disabled: readonly,
            },
        };

        this.edge_id = {
            type:         'select2',
            key:          'edge_id',
            label:        'form.field.edge',
            size:         6,
            required:     false,
            remoteSource: () => this.constructionsRepository.allByType(ConstructionType.OE_EDGES),
            attributes:   {
                disabled: readonly,
            },
        };

        this.branch_id = {
            type:         'select2',
            key:          'branch_id',
            label:        'form.field.branch',
            size:         6,
            required:     false,
            remoteSource: () => this.constructionsRepository.allByType(ConstructionType.OE_BRANCHES),
            attributes:   {
                disabled: readonly,
            },
        };

        this.code = {
            type:       'text',
            key:        'code',
            label:      'form.field.code',
            size:       6,
            required:   false,
            attributes: {
                maxlength: 10,
                readonly:  readonly,
            },
            inputGroup: {
                position: 'right',
                visible:  !readonly,
                button:   {
                    visible: Number(viewModel.construction_type_id) !== ConstructionType.OH_TYPES,
                    action:  () => this.openSelectionModal(viewModel),
                    icon:    {
                        class: 'icon-search4',
                    },
                },
            },
        };

        const schema = [
            [this.pks, this.pkf],
            [this.pk_type_id, this.pk_type_code],
            [this.number_of_lanes, this.axis_id],
            [this.edge_id, this.branch_id],
            [this.code],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Opens the selection modal
     */
    openSelectionModal(viewModel) {
        this.dialogService.open({
            viewModel: ExternalBranchesListingModal,
            model:     {},
        }).whenClosed((response) => {
            if (!response.wasCancelled) {
                viewModel.formSchema.code.instance.model.value = response.output.code;
            }
        });
    }

    /**
     * Focus on a different field
     * @param fieldKey
     */
    focusField(fieldKey) {
        document.querySelector(`#${fieldKey}`)?.focus();
    }
}
