import { inject }              from 'aurelia-framework';
import { ListingRevision }     from 'modules/management/models/listing-revision';
import { BulletinsRepository } from 'modules/management/specifications/bulletins/services/repository';

@inject(BulletinsRepository)
export class ListingRevisionFormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        bulletins: [],
    };

    /**
     * Constructor
     *
     * @param bulletinsRepository
     */
    constructor(bulletinsRepository) {
        this.bulletinsRepository = bulletinsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ListingRevision}
     */
    model(nextListingRevisionNumber, latestSelectedBulletins) {
        let model = new ListingRevision();

        this.modelDefaults.revision_number = nextListingRevisionNumber;
        this.modelDefaults.bulletins       = latestSelectedBulletins;

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.revision_number = {
            type:       'number',
            key:        'revision_number',
            label:      'form.field.revision-number',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.effective_date = {
            type:            'material-ui-date-picker',
            key:             'effective_date',
            label:           'form.field.effective-date',
            size:            12,
            valueConverters: [
                { name: 'dateFormat' },
            ],
            attributes:      {
                disabled: readonly,
            },
        };

        this.bulletins = {
            type:         'duallistbox',
            key:          'bulletins',
            label:        'form.field.bulletins',
            required:     false,
            size:         12,
            remoteSource: () => this.bulletinsRepository.allWithCode(),
            attributes:      {
                disabled: readonly,
            },
        };

        const schema = [
            [this.revision_number, this.effective_date],
            [this.bulletins],
        ];

        if (readonly) {
            this.closed_date = {
                type:            'material-ui-date-picker',
                key:             'closed_date',
                label:           'form.field.closed-date',
                size:            12,
                valueConverters: [
                    { name: 'dateFormat' },
                ],
                attributes:      {
                    disabled: true,
                },
            };

            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       12,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       12,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.closed_date], [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
